EMMY.blurSave = {
  init: function () {
    EMMY.entrants.entryId = document.getElementById('entryId').innerText;
    EMMY.blurSave.activeAjaxUpdate = true;
    EMMY.blurSave.thirdPartyUpload();

    if($('.admin-save').length > 0) {
      EMMY.blurSave.adminSaver();
    } else {
      EMMY.blurSave.submitter(false);
    }
    $(".save-button, a#SaveButton").on('click', function(e) {
      e.preventDefault();
      var formID = $('#entry-fulldetails-form');
      EMMY.blurSave.ajaxCall(formID, false);
      // EMMY.entrants.ajaxBlurEntrant(true);

      $(".save-button, a#SaveButton").attr("style","opacity:0.5; -moz-opacity:0.5; filter:alpha(opacity=50)");
      setTimeout(function(){ window.location.href = '/'; }, 2000);
    });
    $('.blurSave:not(.entryAdmin) .form-control.word-count').on('keyup', function(e) {
      var that = $(this);
      var includeSave = false;
      _wordChecker(that, includeSave);
    });


  },
  thirdPartyUpload: function() {
    $('.file_upload_email').on('keyup', function(e) {
      $(this).closest('.form-group-beside').find('.email-send-message').css('opacity', 0);
      $(this).closest('.form-group-beside').find('button').removeAttr('disabled');
    });

    $('.admin-send-thirdparty-upload').on('click', function(e) {
      e.preventDefault();
      console.log('clicked');
      var fileid = $(this).data('file-id');
      var email = $('.file_upload_email.file-id-'+fileid).val();
      console.log(email);
      console.log(fileid);
      $('.file_upload_email.file-id-'+fileid).closest('.form-group-beside').find('.email-send-message').css('opacity', 1);
      $.ajax({
        url: '/uploadlink_store/' + $(this).data('entry-id'),
        type: 'put',
        data: {fileid: fileid, email: email},
        success: function (data) {
          // need some way to show success
          $('.file_upload_email.file-id-'+fileid).css('background-color','#00d60040');
          $('.file_upload_email.file-id-'+fileid).closest('.form-group-beside').find('.email-send-message').text('Sent');
          $('.file_upload_email.file-id-'+fileid).closest('.form-group-beside').find('button').attr('disabled', true);
        }
      });
    });
  },
  submitter: function(reload) {
    var formID = $('#entry-fulldetails-form');
    if (formID.val() != undefined) {
      EMMY.blurSave.actualAjaxSaving(formID, reload);
    }
  },
  actualAjaxSaving: function(formID, reloadForm) {
    formID.find('.form-control:not(.no-blur)').on('change', function (e) {
      if($(this).hasClass('body_of_work')) {
        reloadForm = true;
      }
      if($(this).hasClass('word-count')) {
        var words = $(this).val().match(/\S+/g).length;
        var maxWords = Number($(this).data('max-word-count'));
        if (words > maxWords) {
          $(this).addClass('too-many-words');
        } else {
          $(this).removeClass('too-many-words');
          EMMY.blurSave.ajaxCall(formID, reloadForm);
        }
      } else {
        EMMY.blurSave.ajaxCall(formID, reloadForm);
      }
    });
  },
  ajaxCall: function(formID, reloadForm) {
    if (!EMMY.blurSave.activeAjaxUpdate) {
      return;
    }
    EMMY.blurSave.activeAjaxUpdate = false;

    // Truncate max word count to MAX number if over before saving
    $('.blurSave:not(.entryAdmin) #entry-fulldetails-form .form-control.word-count').each(function(i,v) {
      if($(this).val()) {
        var maxWords = Number($(this).data('max-word-count'));
        var words = $(this).val().match(/\S+/g).length;
        var desiredInput = $(this).val();
        desiredInput = desiredInput.replace(/[ ]{2,}/gi," ");
        var truncatedOutput = desiredInput.split(" ").splice(0, maxWords).join(" ");
        $(this).val(truncatedOutput);
        $(this).removeClass('too-many-words');
        $(this).parent().find('.overlimit-word-count').hide();
      }
    });

    var dataForm = formID.serialize();
    $.ajax({
      url: '/entry/' + EMMY.entrants.entryId,
      type: 'put',
      data: dataForm,
      success: function (data) {
        console.log('blur saved', reloadForm);
        EMMY.blurSave.activeAjaxUpdate = true;
        var mainWrapper = document.querySelector('.main');
        mainWrapper.className = 'main oneColumn col-8 col-md-10 editPage ' + data.status;
        var status = data.status;
        if (status == 'submitted') {
          status = 'Complete';
        } else if (status == 'ready_ballot') {
          status = 'Approved';
        }
        $('.submission-status-value').text(status);
        if (reloadForm) {
          location.reload();
        }
      }
    });
  },
  adminSaver: function() {
    $('.admin-save').on('click', function(e) {
      e.preventDefault();
      var currentTimestamp = Number($('#timestamp').text());
      EMMY.blurSave.ajaxTimestamp(currentTimestamp);
    });
  },
  ajaxTimestamp: function(currentTimestamp) {
    $.ajax({
      url: '/api/entry/' + EMMY.entrants.entryId + '/timestamp',
      type: 'get',
      success: function (data) {
        console.log(currentTimestamp, 'timestamps', data.timestamp);
        if (currentTimestamp < data.timestamp) {
          console.log('changes made', 'activate modal');
          $('body').addClass('modal-open-third');
          $('.reload').on('click', function(e) {
            // console.log('reload page');
            location.reload();
          });
          $('.confirm-save').on('click', function(e) {
            var formID = $('#entry-fulldetails-form');
            EMMY.blurSave.ajaxCall(formID, false);
            location.reload();
            // EMMY.entrants.ajaxBlurActiveSave = true;
            // EMMY.entrants.ajaxBlurEntrant(true);
            // console.log('save the data anyway!');
          });
        } else {
          var formID = $('#entry-fulldetails-form');
          EMMY.blurSave.ajaxCall(formID, false);
          // EMMY.entrants.ajaxBlurActiveSave = true;
          // EMMY.entrants.ajaxBlurEntrant(true);
          // console.log('save the data');
        }
      }
    });
  },
  imisCheckOnLoad: function() {
    $('.imis-entrant-form-field').each(function(i,v) {
      var imisValid = $(this).attr('data-imis-valid');
      var imisValue = $(this).val();
      if((imisValid == 'false') && (imisValue != '')) {
        $(this).closest('.form-group-beside').addClass('required');
        var entrantId = $(this).closest('.entrant-listing-item').find('.entrantIdVal').val();
        $('.entrant-listing-item-'+entrantId).addClass('needsAttention');
        $(this).addClass('imis-needsfixing');
      }
    });
  }
};

function _wordChecker(that) {
  if (that.val().match(/\S+/g) == null) { return; }
  var words = countWords(that.val());//that.val().match(/\S+/g).length;
  var maxWords = Number(that.data('max-word-count'));
  if (words > maxWords) {
    let numWords = Number(words) - Number(maxWords);
    if(numWords == 1) {
      numOver = numWords + ' word';
    } else {
      numOver = numWords + ' words';
    }
    that.addClass('too-many-words');
    that.parent().find('.overlimit-word-count span').text(numOver);
    that.parent().find('.overlimit-word-count').show();
  } else {
    that.removeClass('too-many-words');
    that.parent().find('.overlimit-word-count').hide();
  }
}