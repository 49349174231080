//This is the functionality for the category
EMMY.changeMass = {
  init: function () {
    console.log('changing');
    $('.table-checkbox').on('click', function (e) {
      calculateNumSelected();
      if (!$('.change-mass-slide').hasClass('slideUp')) {
        $('.change-mass-slide').addClass('slideUp');
        $('#searchPage').addClass('extraPadding');
      }
    });

    $(document).on('click', '.select-all-change', function(e) {
      e.preventDefault();
      if($(this).hasClass('unselectall')) {
        $(this).removeClass('unselectall');
        _unCheckAllBoxesSearch();
      } else {
        $(this).addClass('unselectall');
        _checkAllBoxesSearch();
      }
      calculateNumSelected();
    });
    $('.close-button').on('click', function (e) {
      e.preventDefault();
      $('.change-mass-slide').removeClass('slideUp');
      $('#searchPage').removeClass('extraPadding');
    });
    $('.change-mass-button').on('click', function (e) {
      $('#changeResults').html("");
    });
  }
};

function _unCheckAllBoxesSearch() {
  $('#admin-entries .table-checkbox input:checked').each(function(i,v) {
    $(this).prop('checked',false);
  });
}
function _checkAllBoxesSearch() {
  $('#admin-entries .table-checkbox input:not(:checked)').each(function(i,v) {
    $(this).prop('checked',true);
  });
}

function calculateNumSelected() {
  var numSelected = $('.table-checkbox input:checked').length;
  var totalRows = $('#admin-entries tbody tr').length + 1;
  if ($('#selectAll:checked').length > 0) {
    if (numSelected == totalRows) {
      numSelected = $('#totalEntries').val();
      $('#selectAll').prop('checked',true);
    } else {
      numSelected = $('#totalEntries').val() - (totalRows - numSelected);
      $('#selectAll').prop('checked',false);
    }
  }
  $('.change-mass-slide span').text(numSelected);
}