EMMY.judging = {
    init: function() {
        console.log('judging loaded');
        EMMY.entrants.entryId = document.getElementById('entryId').innerText;
        EMMY.judging.saveJudgementQuestion();
        EMMY.judging.textToLink();
        EMMY.judging.pdfResize();

    },
    saveJudgementQuestion: function() {
        $('#entry-judgement-form select').on('change', function() {
            var field = $(this).prop('name');
            var value = $(this).prop('value');
            var entryId = $('#entryId').html();
            var url = $('form#entry-judgement-form').attr('action');

            console.log('updating entry '+entryId);

            $.ajax({
                url: url,
                type: 'post',
                data: {
                    field: field,
                    value: value
                },
                success: function(data) {
                    // need some way to show success
                    console.log(data);
                    // check if all select fields from the #entry-judgement-form have values if so print out success message
                    var allValuesFilledOut = true;
                    $('#entry-judgement-form select').each(function(i) {
                        if( !$(this).val() ) {
                            allValuesFilledOut = false;
                        };
                    });
                    if(allValuesFilledOut) {
                        $('#entry-judgement-form .submit-button').removeAttr('disabled');
                    }
                }
            });
        });
    },
    textToLink: function() {
        $('.form-group-stacked .entry-field__value.video-link').linkify();
    },
    pdfResize: function() {
        $('.new-modal-trigger.preview').on('click', function(e) {
            newHeight = $(window).height()*0.75;
            $(this).closest('.entry-field').find('object').height(newHeight);
        });
    }
};
