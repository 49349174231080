EMMY.tablesort = {
  init: function () {
      EMMY.tablesort.tableSort();
      EMMY.tablesort.stackableTable();
      EMMY.tablesort.questionHover();
  },
  tableSort: function() {
    var pageVal = EMMY.tablesort.tablePage();
    var sortClass = EMMY.tablesort.sortClassFromPage(pageVal);
    var sortDirection = EMMY.tablesort.sortDirectionFromPage(pageVal);
    var perPageNum = EMMY.tablesort.perPageFromPage(pageVal);
    var allEntries = $('#sortable-table tr').length;
    if (allEntries > 100) {
      var perPageSelect = [10,20,50,100, allEntries];
    } else {
      var perPageSelect = [10,20,50, allEntries];
    }

    var lengthMenuDropDown = '<span>Show</span> <select>';
    Array.from(perPageSelect).forEach(function(pageNumVal, i) {
      if (i < perPageSelect.length - 1) {
        lengthMenuDropDown += '<option value="' + pageNumVal + '">'+ pageNumVal + ' Entries</option>';
      } else {
        lengthMenuDropDown += '<option value="' + pageNumVal + '">'+ 'All' + ' Entries</option>';
      }
    });
    lengthMenuDropDown += '</select><span> per page</span>';

    var options = {
      "dom": 'tpilr',
      "pageLength": Number(perPageNum),
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"] ],
      'order': [[sortClass, sortDirection]],

      // "initComplete": function(settings, json) {
      //   console.log('init complete')
      //   EMMY.tablesort.clickableLinks();
      //   EMMY.tablesort.clickableRows();
      //   EMMY.tablesort.saveData(pageVal);
      //   EMMY.tablesort.entrantNameLength();
      // },
      'drawCallback': function(settings,json) {
        EMMY.tablesort.clickableLinks();
        EMMY.tablesort.clickableRows();
        EMMY.tablesort.saveData(pageVal);
        EMMY.tablesort.entrantNameLength();
      },
      "language": {
        "lengthMenu": lengthMenuDropDown,
        "emptyTable": "No results returned"
      }
    };
    if(pageVal == 'checkout') {
      options.columnDefs = [{
        "targets": 'no-sort',
        "orderable": false,
      }]
    }

    var myTable = $('.' + pageVal +'-table-wrap > #sortable-table');
    var dataTable = myTable.DataTable(options);
    dataTable.on('draw', function () {
      EMMY.tablesort.clickableReActivate();
      EMMY.modals.init();
    });
  },
  clickableLinks: function() {
      $('table:not(.regular-click) td a:not(.modal)').on('click', function (event) {
        if (!$(this).hasClass('proof')) {
          var target = event.target || event.srcElement;
          window.location.href = target.href;
        }
      });
  },
  clickableRows: function() {
    if(($('.invoice-table-wrap').val() == undefined) && ($('.checkout-table-wrap').val() == undefined)) {
      $('tr:not(.status-deleted) td').on('click', function(event) {
        if (!event.target.classList.contains('fa') && (!event.target.classList.contains('proof'))) {
          event.preventDefault();
          var hrefVal = this.parentElement.dataset.rowhref;
          window.location.href = hrefVal;
        }
      });
      EMMY.tablesort.clickableReActivate();
    }
  },
  stackableTable: function() {
    // escape if it's on the checkout page
    if ($('.checkout-table').val() != undefined) {
      return 'checkout';
    }
    $('#sortable-table').cardtable();
  },
  questionHover: function() {
    if(document.querySelector('.exclaim-icon')) {
      var thHover = document.querySelector('.exclaim-icon');
      var thStatusWindow = document.querySelector('.status-wrap');
      thHover.addEventListener('mouseover', function () {
        thStatusWindow.classList.toggle('showme');
      });
      thHover.addEventListener('mouseout', function () {
        thStatusWindow.classList.toggle('showme');
      });
    }
  },
  saveData: function(pageSelected) {
    $('th').on('click', function(event) {
        var headerClass = this.className.split(' ');
        localStorage.setItem('sortClassIndex-'+ pageSelected, $(this).index());
        var direction;
        // we need to reset because the asc & desc are reverse due to when they're set.
        if (headerClass[1] == 'sorting_desc') {
          direction = 'desc'
        } else {
          direction = 'asc'
        }
        localStorage.setItem('sortDirection-'+pageSelected, direction);
    });

    $('#sortable-table_length select').on('change', function (event) {
      var numPerPage = this.value;
      localStorage.setItem('numPerPage-'+pageSelected, numPerPage);
    });
  },
  entrantNameLength: function() {
    $('.entries-entrant-name').each(function(i,v){
      var nameVal = $(this).text();
      if (nameVal.length > 20) {
        var modNameVal =
        $(this).text()
      }
    });
  },
  tablePage: function() {
    if ($('.entries-table').val() != undefined) {
      return 'entries';
    }
    if ($('.invoice-table').val() != undefined) {
      return 'invoice';
    }
    if ($('.reports-table').val() != undefined) {
      return 'reports';
    }
    if ($('.checkout-table').val() != undefined) {
      return 'checkout';
    }
  },
  sortClassFromPage: function(pageSelected) {
    var sortClass = '0';
    if (localStorage.getItem('sortClassIndex-' + pageSelected)) {
      sortClass = localStorage.getItem('sortClassIndex-' + pageSelected);
    }
    return sortClass;
  },
  sortDirectionFromPage: function(pageSelected) {
    var sortDirection = 'asc';
    if (localStorage.getItem('sortDirection-' + pageSelected)) {
      sortDirection = localStorage.getItem('sortDirection-' + pageSelected);
    }
    return sortDirection;
  },
  perPageFromPage: function(pageSelected) {
    var perPageNum = '10';
    if (localStorage.getItem('numPerPage-' + pageSelected)) {
      perPageNum = localStorage.getItem('numPerPage-' + pageSelected);
    }
    if($('.checkout-table').val() != undefined) {
      perPageNum = jQuery('#sortable-table tbody tr').length;
    }
    return perPageNum;
  },
  clickableReActivate: function() {
    $('.reactivate-button').unbind('click');
    $('.reactivate-button').on('click', function(e) {
      e.preventDefault();
      var entryId = $(this).data('entry-id');
      var modal = document.querySelector('.modal-fade-screen-second');
      modal.setAttribute('entries_id', entryId);
    });
    $('.entries-reActivate').unbind('click');
    $('.entries-reActivate').on('click', function(e) {
      e.preventDefault();
      var modal = document.querySelector('.modal-fade-screen-second');
      var entryId = modal.getAttribute('entries_id', entryId);
      EMMY.tablesort.ajaxActivateRequest(entryId);
    });
  },
  ajaxActivateRequest: function(entryId) {
    var ajaxBlurActiveSave = true;
    if (!ajaxBlurActiveSave) {
      return;
    }
    ajaxBlurActiveSave = false;
    $.ajax({
      url: 'api/messages/sendReactivate/'+entryId,
      success: function (data) {
        ajaxBlurActiveSave = true;
        console.log(data, 'Success message saved');
        EMMY.tablesort.modalSwapContentSuccess();
      },
      error: function (data) {
        console.log("error submitting message");
      }
    });
  },
  modalSwapContentSuccess: function() {
    $('.modal-fade-screen-second .modal-inner').toggleClass('showSuccess');
    setTimeout(function() {
      $('body.modal-open-second').removeClass('modal-open-second');
    }, 1000);
  }
}
