// This creates the autocomplete functionality for entrants

EMMY.entrantAutocomplete = {
  init: function() {
    EMMY.entrantAutocomplete.finishingLoop = false;
    EMMY.entrantAutocomplete.firstName();
    EMMY.entrantAutocomplete.lastName();
    EMMY.entrantAutocomplete.imisId();
    EMMY.entrantAutocomplete.care_of();
    EMMY.entrantAutocomplete.address_1();
    EMMY.entrantAutocomplete.inputToChange = '';
  },
  firstName: function () {

    var firstName = $('#entrants-form-block .first_name');
    firstName.each(function(index,value) {
      var entrantNameInput = $(this);
      
      var optionsFirstName = {
        url: function(phrase) {
          if (phrase !== "") {
            return "/api/entrants/"+EMMY.entrants.entryId+"?first_name=" + phrase;
          }
        },

        getValue: function(element) {
          return element.first_name;
        },
        minCharNumber: 2,
        listLocation: 'entrants',
        highlightPhrase: true,

        ajaxSettings: {
          dataType: "json",
          method: "POST",
        },

        template: {
          type: "custom",
          method: function(value, item) {
            if(item.imis_id) {
              var fullName = item.first_name + " " + item.last_name + ' - ' + item.imis_id;
            }  else {
              var fullName = item.first_name + " " + item.last_name;
            }
            fullName = '<strong>' + fullName + '</strong><br>' + item.address_1 + '<br>' + item.city + ', ' + item.zip;

            return fullName;
          }
        },
        list: {
          maxNumberOfElements: 10,
          match: {
            enabled: true
          },
          sort: {
            enabled: true
          },
          onChooseEvent: function() {
            var selectedData = entrantNameInput.getSelectedItemData();
            var entrantItem = entrantNameInput.closest('.entrant-item');
            var lastIndex;
            EMMY.entrants.ajaxBlurActiveSave = false;
            $.when (
              $.each(selectedData, function(i,v) {
                lastIndex = i;
                _customEntrantAutocompleteFields(entrantItem, v);
                _entrantAutocompleteFields(entrantItem, i, v);
              })
          ).then(function() {
              EMMY.entrants.bindListing();
              EMMY.entrantAutocomplete.finishingLoop = true;
            });
          },
        },
        requestDelay: 100,
        theme: "square"
      };

      entrantNameInput.easyAutocomplete(optionsFirstName);
    });

  },
  lastName: function () {

    var lastName = $('#entrants-form-block .last_name');
    lastName.each(function(index,value) {
      var entrantNameInput = $(this);

      var optionsLastName = {
        url: function(phrase) {
          if (phrase !== "") {
            return "/api/entrants/"+EMMY.entrants.entryId+"?last_name=" + phrase;
          }
        },

        getValue: function(element) {
          return element.last_name;
        },
        minCharNumber: 2,
        listLocation: 'entrants',
        highlightPhrase: true,

        ajaxSettings: {
          dataType: "json",
          method: "POST",
        },

        template: {
          type: "custom",
          method: function(value, item) {
            if(item.imis_id) {
              var fullName = item.first_name + " " + item.last_name + ' - ' + item.imis_id;
            }  else {
              var fullName = item.first_name + " " + item.last_name;
            }
            fullName = '<strong>' + fullName + '</strong><br>' + item.address_1 + '<br>' + item.city + ', ' + item.zip;

            return fullName;
          }
        },
        list: {
          maxNumberOfElements: 16,
          match: {
            enabled: true
          },
          sort: {
            enabled: true
          },
          onChooseEvent: function() {
            var selectedData = entrantNameInput.getSelectedItemData();
            var entrantItem = entrantNameInput.closest('.entrant-item');
            var lastIndex;
            $.when(
              $.each(selectedData, function(i,v) {
                lastIndex = i;
                _customEntrantAutocompleteFields(entrantItem, v);
                _entrantAutocompleteFields(entrantItem, i, v);
              })
            ).then(function() {
              EMMY.entrants.bindListing();
              EMMY.entrantAutocomplete.finishingLoop = true;
            });
          },
        },
        requestDelay: 100,
        theme: "square"
      };

      entrantNameInput.easyAutocomplete(optionsLastName);
    });

  },
  imisId: function () {

    var imisID = $('#entrants-form-block .imis_id');
    imisID.each(function(index,value) {
      var entrantNameInput = $(this);

      var optionsImisIDName = {
        url: function(phrase) {
          if (phrase !== "") {
            return "/api/entrants/"+EMMY.entrants.entryId+"?imis_id=" + phrase;
          }
        },

        getValue: function(element) {
          return element.imis_id;
        },
        minCharNumber: 2,
        listLocation: 'entrants',
        highlightPhrase: true,

        ajaxSettings: {
          dataType: "json",
          method: "POST",
        },

        template: {
          type: "custom",
          method: function(value, item) {
            if(item.imis_id) {
              var fullName = item.first_name + " " + item.last_name + ' - ' + item.imis_id;
            }  else {
              var fullName = item.first_name + " " + item.last_name;
            }
            fullName = '<strong>' + fullName + '</strong><br>' + item.address_1 + '<br>' + item.city + ', ' + item.zip;

            return fullName;
          }
        },
        list: {
          maxNumberOfElements: 16,
          match: {
            enabled: true
          },
          sort: {
            enabled: true
          },
          onChooseEvent: function() {
            var selectedData = entrantNameInput.getSelectedItemData();
            var entrantItem = entrantNameInput.closest('.entrant-item');
            var lastIndex;
            $.when(
              $.each(selectedData, function(i,v) {
                lastIndex = i;
                _customEntrantAutocompleteFields(entrantItem, v);
                _entrantAutocompleteFields(entrantItem, i, v);
              })
            ).then(function() {
              EMMY.entrants.bindListing();
              EMMY.entrantAutocomplete.finishingLoop = true;
            });
          },
        },
        requestDelay: 100,
        theme: "square"
      };

      entrantNameInput.easyAutocomplete(optionsImisIDName);
    });

  },
  care_of: function () {

    var careOf = $('#entrants-form-block .care_of');
    careOf.each(function(index,value) {
      var entrantNameInput = $(this);

      var optionscareOf = {
        url: function(phrase) {
          if (phrase !== "") {
            return "/api/entrants/"+EMMY.entrants.entryId+"?care_of=" + phrase;
          }
        },

        getValue: function(element) {
          return element.care_of;
        },
        minCharNumber: 2,
        listLocation: 'entrants',
        highlightPhrase: true,

        ajaxSettings: {
          dataType: "json",
          method: "POST",
        },

        template: {
          type: "custom",
          method: function(value, item) {
            care_of_address = item.care_of + '<br>' + item.address_1 + '<br>' + item.city + ', ' + item.zip;

            return care_of_address;
          }
        },
        list: {
          maxNumberOfElements: 10,
          match: {
            enabled: true
          },
          sort: {
            enabled: true
          },
          onChooseEvent: function() {
            var selectedData = entrantNameInput.getSelectedItemData();
            var entrantItem = entrantNameInput.closest('.entrant-item');
            var lastIndex;
            var lastVal;
            $.when(
              $.each(selectedData, function(i,v) {
                lastIndex = i;
                if((i != 'first_name') && (i != 'last_name') && (i != 'middle_name') && (i != 'imis_id')) {
                  _entrantAutocompleteFields(entrantItem, i, v);
                }
              })
            ).then(function() {
              EMMY.entrants.bindListing();
              EMMY.entrantAutocomplete.finishingLoop = true;
            })
          },
        },
        requestDelay: 100,
        theme: "square"
      };

      entrantNameInput.easyAutocomplete(optionscareOf);
    });

  },
  address_1: function () {

    var address1 = $('#entrants-form-block .address_1');
    address1.each(function(index,value) {
      var entrantNameInput = $(this);

      var optionsaddress1 = {
        url: function(phrase) {
          if (phrase !== "") {
            return "/api/entrants/"+EMMY.entrants.entryId+"?address_1=" + phrase;
          }
        },

        getValue: function(element) {
          return element.address_1;
        },
        minCharNumber: 2,
        listLocation: 'entrants',
        highlightPhrase: true,

        ajaxSettings: {
          dataType: "json",
          method: "POST",
        },

        template: {
          type: "custom",
          method: function(value, item) {
            fullAddress = item.address_1 + '<br>' + item.city + ', ' + item.zip;

            return fullAddress;
          }
        },
        list: {
          maxNumberOfElements: 10,
          match: {
            enabled: true
          },
          sort: {
            enabled: true
          },
          onChooseEvent: function() {
            var selectedData = entrantNameInput.getSelectedItemData();
            var entrantItem = entrantNameInput.closest('.entrant-item');
            var lastIndex;
            $.when(
              $.each(selectedData, function(i,v) {
                lastIndex = i;
                if((i != 'first_name') && (i != 'last_name') && (i != 'middle_name') && (i != 'imis_id')) {
                  _entrantAutocompleteFields(entrantItem, i, v);
                }
              })
            ).then(function() {
              EMMY.entrants.bindListing();
              EMMY.entrantAutocomplete.finishingLoop = true;
            })
          },
        },
        requestDelay: 100,
        theme: "square"
      };

      entrantNameInput.easyAutocomplete(optionsaddress1);
    });

  }
};

function _entrantAutocompleteFields(entrantItem, autoCompleteKey, autoCompleteVal) {
  if((autoCompleteKey == 'state') || (autoCompleteKey == 'country')) {
    EMMY.common.entrantStateChanged = false;
    entrantItem.find('.' + autoCompleteKey).val(autoCompleteVal).trigger('change.select2');
  } else {
    entrantItem.find('.' + autoCompleteKey).val(autoCompleteVal).trigger('change');
  }
}

function _customEntrantAutocompleteFields(entrantItem, autoCompleteVal) {
  if (typeof(autoCompleteVal) == 'object') {
    $.each(autoCompleteVal, function(customIndex,customValue) {
      entrantItem.find('.' + customIndex).val(customValue).trigger('change');
    });
  }
}