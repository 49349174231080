//This is the functionality for the customfields (episode_name)
EMMY.customFields = {
  init: function () {
    $('.episode_name_block').each(function(i,v) {
      EMMY.customFields.initialEpisodeChecker(this);
    });

    $('.createNewEpisode').on('click', function (e) {
      customFieldId = $(this).data('field-id');
      e.preventDefault();
      EMMY.customFields.bringNewForm(customFieldId);
    });

    var deleteEpisode = $('.deleteNewEpisode');
    deleteEpisode.on('click', function (e) {
      e.preventDefault();
      $(this).parent().find('.episode_details:last-child').remove();
      customFieldId = $(this).data('field-id');
      EMMY.customFields.episodeButtons(this);
      var formID = $('#entry-fulldetails-form');
      EMMY.blurSave.ajaxCall(formID);
    });
  },
  episodeButtons: function(that) {
    var parentBlock = $(that).closest('.episode_name_block');
    var maxEpisode = Number(parentBlock.find('.episodeList').data('maxepisodes')) - 1;
    var currentIndex = parentBlock.find('.episode_details:last-child').data('details-index');
    var numEpisodes = Number(parentBlock.find('.episodeList .episode_details').length);
    var minEpisode = Number(parentBlock.find('.episodeList').data('minepisodes')) - 1;
    if((minEpisode < 0) || isNaN(minEpisode)) { minEpisode = 0; }
    if((maxEpisode < 0) || isNaN(maxEpisode)) { maxEpisode = 14; }
    if (currentIndex == minEpisode) {
      parentBlock.find('#deleteNewEpisode').hide();
    } else {
      parentBlock.find('#deleteNewEpisode').show();
    }
    if (currentIndex < maxEpisode) {
      parentBlock.find('#createNewEpisode').show();
    } else {
      parentBlock.find('#createNewEpisode').hide();
    }

    // // Now removing the required field portions on episode blocks.
    if(minEpisode > 0) {
      for(i=0; i < minEpisode; i++) {
          parentBlock.find('.episode_details').each(function(i,v) {
              if($(this).data('details-index') > minEpisode) {
                  $(this).find('input').attr('required', false);
                  $(this).find('label em').hide();
                }
            });
        }
    } else {
      parentBlock.find('.episode_details:not(:first-child) input').attr('required', false);
      parentBlock.find('.episode_details:not(:first-child) label em').hide();
    }
  },
  initialEpisodeChecker: function(that) {
    var parentBlock = $(that);
    var maxEpisode = Number(parentBlock.find('.episodeList').data('maxepisodes')) - 1;
    var currentIndex = parentBlock.find('.episode_details:last-child').data('details-index');
    var numEpisodes = Number(parentBlock.find('.episodeList .episode_details').length);
    var minEpisode = Number(parentBlock.find('.episodeList').data('minepisodes')) - 1;
    if(minEpisode < 0) { minEpisode = 0; }
    if (currentIndex == maxEpisode) {
      parentBlock.find('#createNewEpisode').hide();
    }
    if (currentIndex == minEpisode) {
      parentBlock.find('#deleteNewEpisode').hide();
    }
  },
  bringNewForm: function (customFieldId) {
    idVal = Number($('#episode-list-'+customFieldId+' .episode_details:last').data('details-index')) + Number(1);
    $.ajax({
      url: '/api/episode_name/form/' + customFieldId + '/' + idVal,
      type: 'get',
      success: function (data) {
        var formRight = $('#episode-list-' + customFieldId);
        formRight.append(data);
        EMMY.common.datePicker();
        EMMY.customFields.episodeButtons('#episode-list-'+customFieldId);
        EMMY.blurSave.submitter();
      }
    });

  },
};