var wrapper = '';
var canvas = '';
EMMY.signature = {
  init: function() {
    if ($('.signature-show').val() != undefined) {
      $('#NextButton').removeAttr('disabled');
    }

    wrapper = document.getElementById("signature-pad");
    canvas = wrapper.querySelector("canvas");
    //This is the Signature Functionality
    var clearButton = wrapper.querySelector("[data-action=clear]"),
        saveButton = wrapper.querySelector("[data-action=save]"),
        signaturePad;
    var signatureBox = document.getElementById('signaturebox');
    var signatureData = document.getElementById('signature');
    var signatureRequired = document.getElementById('signature-placeholder');

    $('.m-signature-pad--body canvas').on('click',function(e) {
      $(this).removeClass('required');
    });

    window.onresize = EMMY.signature.resizeCanvas;
    EMMY.signature.resizeCanvas()

    clearButton.addEventListener("click", function (event) {
      signaturePad.clear();
      $('.signature-block').removeClass('hide-me');
      $('.review-notes .red').removeClass('hide-me');
      if($('.hairmakeup-review').val() == undefined) {
        $('#signaturebox').html('');
        signatureBox.style.display = "none";
        $.ajax({
          url: '/entry/' + EMMY.entrants.entryId,
          type: 'put',
          data: {'signature':''},
          success: function (data) {
            // console.log('Deleted signature');
          }
        });
      }
    });

    saveButton.addEventListener("click", function (event) {
      if (signaturePad.isEmpty()) {
        $('.m-signature-pad--body canvas').addClass('required');
      } else {
        signatureBox.style.display = "inline-block";
        var img = document.createElement('img');
        img.src = signaturePad.toDataURL();
        // console.log(img,'this is img', img.src);
        signatureBox.innerHTML = '';
        signatureBox.appendChild(img);
        signatureData.value = img.src;

        // Save the signature data to the entry.
        if($('.hairmakeup-review').val() == undefined) {
          $.ajax({
            url: '/entry/' + EMMY.entrants.entryId,
            type: 'put',
            data: {'signature': img.src},
            success: function (data) {
              // console.log('Saved signature data.')
              $('.signature-block').addClass('hide-me');
            }
          });
        } else {
          var D = new Date();
          var currentDate = D.getMonth()+1 + '/' + D.getDay() + '/' + D.getFullYear();
          $('#date').val(currentDate)
          $('.date-wrapper').show();
          $('#hair-makeup-submit').attr('disabled', false);
        }

        signatureRequired.value = img.src;
        $('.review-notes .modal').addClass('hide-me');
        $('.review-notes .red').addClass('hide-me');
        $("html, body").animate({ scrollTop: $(document).height() }, 800);
        document.querySelector('body').classList.toggle('modal-open');
        $('#NextButton').removeAttr('disabled');
      }
    });

    var bodyClass = document.querySelector('body');
    $('.modal-signature-button, #signaturebox').on('click', function(e) {
      bodyClass.classList.toggle('modal-open');
      if($(window).width() < 600) {
        canvas.width = $(window).width();
      } else {
        canvas.width = $('.modal-inner.signature').width();
      }
      signaturePad = new SignaturePad(canvas);
    });
  },
  resizeCanvas: function() {
    // var ratio =  Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth; //* ratio;
    canvas.height = canvas.offsetHeight; //* ratio;
    canvas.getContext("2d");//.scale(ratio, ratio);
  },
  deptHeadSubmit: function() {
    $('#dept_signature_form').submit();
  }
}
// END - This is the Signature Functionality
