//This is the functionality for the entrant
EMMY.drag = {
  init: function () {
    if ($('.categoryeditor').val() != undefined) {
      Sortable.create(byId('entryFields'), {
        handle: '.header', //handle: '.fa-arrows',
        animation: 150,
        supportPointer: false,
        onEnd: function(evt){
          EMMY.drag.updateCategoryFieldWeight();
        }
      });
    }

    if ($('.selectItems').val() != undefined) {
      $('.selectItems').each(function(i, v) {
        var that = this;
        var selectID = $(this).attr('id');
        Sortable.create(byId(selectID), {
          handle: '.reorderSelect',
          animation: 150,
          supportPointer: false,
          onEnd: function(evt){
            EMMY.categoryeditor.fixSelectListing(that);
          }
        });
      });
      $('.fa-trash:not(.override-default)').on('click', function(e) {
        $(this).closest('div').remove();
      });
    }

    if($('#category-reorder').val() != undefined) {
      Sortable.create(byId('category-reorder'), {
        handle: '.fa',
        animation: 150,
        supportPointer: false,
        onChoose: function (element) {
          $('#category-reorder').addClass('currently-dragging');
        },
        onEnd: function (evt) {
          $('#category-reorder').removeClass('currently-dragging');
          var changes = [];
          $('#category-reorder .category-row').each(function (i, v) {
            var catNumOrder = i + 1;
            var catElement = $(this).find('span');
            var catId = Number(catElement.attr('data-cat-id'));
            if (catElement.attr('data-original-number') != catNumOrder) {
              changes.push({'id': catId, 'new': catNumOrder});
            }
            catElement.text(catNumOrder);
            catElement.attr('data-original-number', catNumOrder);
          });
          $.ajax({
            url: '/category/reorder',
            type: 'put',
            data: {data: JSON.stringify(changes)},
            dataType: 'json',
            success: function (data) {
              location.reload(true);
            }
          });
          console.log(changes);
        }
      });
    }
  },
  entrantDrag: function() {
    Sortable.create(byId('entrantList'), {
      handle: '.move',
      filter: '.noclick',
      animation: 150,
      supportPointer: false,
      onEnd: function (evt) {
        updateEntrantWeights();
        EMMY.entrants.numberingEntrants();
      }
    });
  },
  updateCategoryFieldWeight: function() {
    // var allLi = document.querySelectorAll('#entryFields .form-group-edit');
    // [].forEach.call(allLi, function(col, i) {
      $('#entryFields .form-group-edit').each(function(i,v) {
        $(this).find('.weight-value').val(i);
      });
      // formId.setAttribute('data-weight-value', i);
    // });
  }
}

var byId = function (id) { return document.getElementById(id); }

function updateEntrantWeights() {
  $('#entrantList li:not(.hide-me)').each(function(i, col) {
    console.log(col);
    var entrantNum = $(this).data('entrant-num');
    var currentWeight = $(this).data('weight-value');
    $(this).data('weight-value', i);
    if (entrantNum != 'not-saved') {
      var formId = $('.entrant-listing-item-' + entrantNum);
      $('#right .entrant-listing-item-'+ entrantNum +' .form-weight').val(i);
    } else {
      var formId = $('.entrant-listing-item.not-saved');
    }
    formId.data('weight-value', i);
  });

  if ($('.entryAdmin').val() == undefined) {
    EMMY.entrants.orderEntrant();
  }
}

