EMMY.validateFormButtonClick = {
  init: function() {
    $('.btn-validate-checker').on('click', function(e) {EMMY.validateFormButtonClick.checkForm(e, $(this));} );
    $('.btn-validate-checker-submit').on('click', function(e) {EMMY.validateFormButtonClick.checkForm(e, $(this), function(evt) {
      if($('#createEntryForm').val() != undefined) {
        $('#createEntryForm').submit();
      } else {
        $(evt.target).closest('form').submit();
      }

    });} );
  },
  checkForm: function(e, thisElem, callback) {
    EMMY.entrants.breakVal = true;
    EMMY.entrants.imisVal = false;
    var that = thisElem;

    if (that.hasClass('btn-step-next')) {
      var formToValidate = $('input,textarea,select').filter('[required],.word-count, .imis_id');
    } else {
      // Find all the form elements for this form.
      var formToValidate = that.parents('form').find('.form-control');
    }

    EMMY.validateForm.checkRequired(formToValidate);
    if (EMMY.entrants.breakVal) {
      if (that.hasClass('btn-step-next') && (!that.hasClass('modal-previous'))) {
        _ajaxRedirectEntryStep();
      } else {
        $(formToValidate).closest('form');
        if(callback) {
          callback(e);
        }
      }
    } else {
      EMMY.common.scrollToTop(true, EMMY.entrants.imisVal);
      e.preventDefault();
    }
  },
}

EMMY.validateForm = {
  init: function() {
    console.log('validate form init');
  },
  checkRequired: function (formToValidate) {
    if (formToValidate == undefined) {
      var formToValidate = $('.form-control');
    }
    formToValidate.each(function(i,v) {
      // console.log($(this).closest('.entrant-right').find('.entrant-item').length, 'this is the number of entrant items');
      if ($(this).closest('.entrant-right').find('.entrant-item').length == 1) {
        console.log('this is the case with the first entry empty')
        EMMY.validateForm.actualValidation(this);
      } else if ($(this).closest('.entrant-item').hasClass('not-saved')) {
        console.log('this is the case with the entrant empty but others present')
      } else if (this.pattern) {
        console.log('this is a pattern');
        EMMY.validateForm.actualValidation(this);
      } else if (this.classList.contains('imis_id')) {
        console.log('checking imis')
        EMMY.validateForm.actualValidation(this);
      } else if (this.required || this.classList.contains('word-count')) {
        console.log('this is the case for all other validation')
        EMMY.validateForm.actualValidation(this);
      }
    });
  },
  actualValidation: function(that) {
    if (that.required) {
      if (that.classList.contains('email')) {
        if (!EMMY.validateForm.validateEmail($.trim(that.value))) {
          EMMY.entrants.breakVal = false;
          that.parentElement.classList.add('required');
          EMMY.validateForm.requiredFixer();
        }
      } else if(($(that).attr('type') == 'checkbox') && (!$(that).hasClass('toggle'))) {
        if(that.checked) {
          $(that).closest('.form-group-beside').removeClass('required');
        } else {
          that.required = true;
          EMMY.entrants.breakVal = false;
          $(that).closest('.form-group-beside').addClass('required');
          EMMY.validateForm.requiredFixer();
        }
      } else if ($(that).hasClass('toggle')) {
        if($(that).parent().find('input:checked').val() == undefined) {
          $(that).closest('.form-group-beside').addClass('required');
          EMMY.entrants.breakVal = false;
          EMMY.validateForm.requiredFixer();
        } else {
          that.required = false;
          $(that).closest('.form-group-beside').removeClass('required');
        }
      } else {
        if (that.value == '') {
          if ($('.categoryeditor').val() != undefined) {
            $(that).closest('.field-wrapper').addClass('required');
          }
          if ($('#categorySelect').val() != undefined) {
            $(that).closest('.form-group').addClass('required');
          }
          if ($('#creditCardForm').val() != undefined) {
            $(that).closest('.form-group').addClass('required');
          }
          $(that).closest('.form-group-beside').addClass('required');
          EMMY.entrants.breakVal = false;
          EMMY.validateForm.requiredFixer();
        }
      }
    }
    if(that.classList.contains('word-count') && that.getAttribute('data-max-word-count')) {
        if (!EMMY.validateForm.validateWordCount(that.value, that.getAttribute('data-max-word-count'))) {
          EMMY.entrants.breakVal = false;
          that.parentElement.classList.add('required');
          EMMY.validateForm.requiredFixer();
        }
    }
    if(that.classList.contains('billing-contact-options') ) {
      if (that.value=="-1") {
        EMMY.entrants.breakVal = false;
        that.parentElement.classList.add('required');
        EMMY.validateForm.requiredFixer();
      }
    }
    if(that.classList.contains('imis-needsfixing') ) {
      var imisLastName = $(that).closest('.entrant-item').find('.last_name').val();
      EMMY.entrants.imisVal = imisLastName;
      EMMY.entrants.breakVal = false;
      $(that).closest('.form-group-beside').addClass('required');
    }
  },
  validateWordCount: function (text, maxWords) {
    if (text=="") {
      return true;
    }
     return text.match(/\S+/g).length <= maxWords;
  },
  validateEmail: function(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  requiredFixer: function() {
    var allRequired = $('.required .form-control');
    allRequired.on('change', function(e) {
        $(this).closest('.form-group-beside').removeClass('required');
        if ($('.entrants').val() != undefined) {
          EMMY.entrants.checkNeedsAttention();
        }
        if ($('.categoryeditor').val() != undefined) {
          EMMY.validateForm.fieldWrapperCheck();
        }
        if ($('#creditCardForm').val() != undefined) {
          $(this).closest('.form-group').removeClass('required');
        }
        if ($('#categorySelect').val() != undefined) {
          $(this).closest('.form-group').removeClass('required');
        }
    });
      $('select').on('change', function(e) {
        $(this).closest('.form-group-beside').removeClass('required');
      });
  },
  fieldWrapperCheck: function() {
    $('.field-wrapper').each(function() {
      var numRequired = $(this).find('.form-group-beside.required').length;
      if (numRequired == 0) {
        $(this).removeClass('required');
      }
    });
  }
}

function _ajaxRedirectEntryStep() {
  $.ajax({
    url: '/entry/' + EMMY.entrants.entryId,
    type: 'put',
    data: {'submission_status':'submitted'},
    success: function (data) {
      if(data.status == 'incomplete') {
        window.location = data.redirect_destination;
      } else {
        $('#savedModal').iziModal('open');
        $('.iziModal-overlay').on('click', function() {
          window.location = data.redirect_destination;
        });
        $('#modal-okButton, #savedModal .iziModal-content .modal-close').on('click', function(){
          window.location = data.redirect_destination;
        });
      }
    }
  });
}

function _deleteEntrantsNotInTeam(entrantArray) {
  entrantArray.shift();
  var promises = entrantArray.map(function(entrantNum, v) {
    var trashURL = '/entrant/' + entrantNum;
    $.ajax({
      url: trashURL,
      method: 'DELETE',
      data: '',
      success: function (data) {
        console.log('Deleted entrants not associated with the team!')
      }
    });
  });
  $.when.apply($, promises).then(function(){
    _ajaxRedirectEntryStep();
  });
}