EMMY.entrantsTeam = {
  init: function () {
    if ($('.team-wrapper').val() != undefined) {
      EMMY.entrantsTeam.teamButton();
      EMMY.entrantsTeam.individualButton();
      EMMY.entrantsTeam.addNewTeamMember();
      EMMY.entrantsTeam.teamEntranttoJson();
      EMMY.entrantsTeam.hideTrash();

      $('.entrant-form-wrapper.team .customfields-field .form-control').attr('required', false);
    }
  },
  teamButton: function() {
      var formID = $('#entry-fulldetails-form');
    $('#entrants-form-block').on('click', '.back-to-team', function (e) {
      e.preventDefault();
      document.querySelector('.entrant-form-wrapper').className = 'entrant-form-wrapper clearfix';

      // Remove CustomFields and required values
      $('#right .customfields-field').addClass('hide-me');
      $('#right .customfields-field .form-control').attr('required', false);

      $(this).closest('.entrant-form-wrapper').addClass('team');
      $('#entrants-form').removeClass('donotShow');
      var entryCategory = $('#category-full-name').text();
      $('.entrant-right .entrant-listing-item:eq(0) .first_name').val(entryCategory);
      $('.entrant-right .entrant-listing-item:eq(0) .last_name').attr('required', false);
      $('#teamNameValue').text(entryCategory);

      $('#team_entrant_eligible').val(1);
      $('.entrant-right .entrant-item').removeClass('active');
      $('.entrant-left .entrant-listing-item').removeClass('active');
      $('.entrant-left .entrant-listing-item:eq(1)').addClass('active');
      $('.entrant-right .entrant-item:first').addClass('active');
      if($('.entryAdmin').val() == undefined) {
        EMMY.blurSave.ajaxCall(formID, false);
      }
    });
  },
  individualButton: function() {
    var formID = $('#entry-fulldetails-form');
    $('#entrants-form-block').on('click', '.back-to-individual:not(.team-entrant-fields)', function (e) {
      console.log('back to team individual');
      e.preventDefault();
      document.querySelector('.entrant-form-wrapper').className = 'entrant-form-wrapper clearfix';

      // Bring back custom fields and the required attributre
      $('#right .customfields-field').removeClass('hide-me');
      $('#right .customfields-field label .yellow').each(function(i,v) {
        $(this).closest('.customfields-field').find('.form-control').attr('required', true);
      });

      $(this).closest('.entrant-form-wrapper').addClass('individual');
      $('#entrants-form').removeClass('donotShow');
      $('.entrant-left .firstname').text('');
      $('.entrant-right .entrant-listing-item:eq(0) .first_name').val('');
      $('.entrant-right .entrant-listing-item:eq(0) .last_name').val('');
      $('.entrant-right .entrant-listing-item:eq(0) .last_name').attr('required', true);
      $('#team_entrant_eligible').val(0);
      if($('.entryAdmin').val() == undefined) {
        EMMY.blurSave.ajaxCall(formID, false);
      }
    });
  },
  addNewTeamMember: function() {
    // var addMoreEntrantTeam = $('.moreTeamMembers');
    // addMoreEntrantTeam.addEventListener('click', function(e) {
    $('.moreTeamMembers').on('click', function(e) {
      e.preventDefault();
      var fullList = document.getElementById('entrantTeamFields');
      var itemToClone = document.querySelector('.all-entrant-team-values');
      var cln = itemToClone.cloneNode(true);
      var spans = cln.querySelectorAll('input');
      Array.prototype.forEach.call(spans, function(el, i) {
        el.value = '';
      });
      fullList.appendChild(cln);
      EMMY.entrantsTeam.removeTeamMember();
      EMMY.entrantsTeam.teamEntranttoJson();
      EMMY.entrantsTeam.hideTrash();
    });
    EMMY.entrantsTeam.removeTeamMember();
  },
  removeTeamMember: function(form) {
    $('.trash-entrant').on('click', function(e) {
      e.preventDefault();
      if ($('#entrantTeamFields .all-entrant-team-values').length > 1) {
        $(this).closest('.all-entrant-team-values').remove();
        EMMY.entrantsTeam.hideTrash();
        _createEntrantJson(this);
        setTimeout(function() {
          EMMY.entrantsTeam.saveEntrantData();
        }, 300);
      }
    });
  },
  teamEntranttoJson: function() {
    $('.team-left-group input').on('blur', function() {

      // @todo -- pass through a delete entrant function
      // $('#right .entrant-item:not(:first)').remove();
      // _actualAjaxDelete(entrantNum);

      _createEntrantJson(this);
      EMMY.entrantsTeam.saveEntrantData();
    });
  },
  saveEntrantData: function(redirect) {
    if(!EMMY.entrants.ajaxBlurActiveSave){
      return;
    }
    EMMY.entrants.ajaxBlurActiveSave = false;
    var dataForm = $('#entrants-form-block').serialize();
    var URL = '/entry/'+EMMY.entrants.entryId+'/entrants';
    $.ajax({
      url: URL,
      method: 'PUT',
      data: dataForm,
      success: function (data) {
        EMMY.entrants.ajaxBlurActiveSave = true;
        if(redirect) {
          _ajaxRedirectEntryStep();
        }
      }
    });
  },
  hideTrash: function() {
    var numTeamMembers = $('#entrantTeamFields .all-entrant-team-values').length;
    if (numTeamMembers == 1) {
      $('.team-left-group .trash-entrant').addClass('hide-me');
    } else {
      $('.team-left-group .trash-entrant').removeClass('hide-me');
    }
  }
};

function _createEntrantJson(that) {
  var jsonVal = new Array();
  $('.entrant-right .entrant-item:first #entrantTeamFields .form-group-beside').each(function(i,v) {
    var memberId = $(this).find('.memberIdTeam').val();
    var firstName = $(this).find('.firstnameTeam').val();
    var lastName = $(this).find('.lastnameTeam').val();
    var credit = $(this).find('.creditTeam').val();
    if ((!credit) && (!memberId) && (!firstName) && (!credit)) {
      return;
    }
    jsonVal.push({
      "memberId" : memberId,
      "firstName" : firstName,
      "lastName"  : lastName,
      "credit"    : credit
    });
  });
  document.getElementById('entrantTeamName0').value = JSON.stringify(jsonVal);
}
