EMMY.ballotPreview = {
  init: function () {
  },
  previewAjax: function() {
    $.ajax({
      url: '/entry/'+ EMMY.entrants.entryId + '/ballot',
      type: 'get',
      success: function (data) {
        var catInst = document.querySelector('.ballot-preview-modal');
        catInst.innerHTML = data;
      }
    });
  }
};