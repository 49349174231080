//This is the functionality for the category
EMMY.category = {
  init: function () {
    var categoryList = document.querySelector('#category');
    if ($('#category :selected').val() != undefined) {
      var catId = $('#category :selected').val();
      EMMY.category.changeCategory(catId);
    }
  },
  changeCategory: function (catId) {
    var catInst = document.querySelector('#catInstructions');
    var categoryWrap = document.querySelector('.catInstructions-wrap');
    catInst.innerHTML = "";
    if (catId) {
      $.ajax({
        url: '/api/category/' + catId,
        type: 'get',
        success: function (data) {
          var catInst = document.querySelector('#catInstructions');
          catInst.innerHTML = data.instructions;
          categoryWrap.querySelector('h3').innerHTML = data.name;
          categoryWrap.style.display = 'block';
          $('#PreviewButton').addClass('active').attr('data-previewcatid',catId);
        }
      });
    }
  }
};