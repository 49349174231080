EMMY.saveURL = {
  init: function () {
    console.log('save URL JS');
  },
  searchPage: function() {
    var parameterVals = window.location.search;
    localStorage.setItem('searchURL', parameterVals);
    $('.clear-button').on('click', function () {
      localStorage.removeItem('searchURL');
    });
  },
  messagesPage: function() {
    var parameterVals = window.location.search;
    localStorage.setItem('messagesURL', parameterVals);
    $('.clear-button').on('click', function () {
      localStorage.removeItem('messagesURL');
    });
  },
  entriesPage: function() {
    var parameterVals = window.location.search;
    localStorage.setItem('entriesURL', parameterVals);
    $('.clear-button').on('click', function () {
      localStorage.removeItem('entriesURL');
    });
  },
  reportsPage: function() {
    var parameterVals = window.location.search;
    localStorage.setItem('reportsURL', parameterVals);
    $('.clear-button').on('click', function () {
      localStorage.removeItem('reportsURL');
    });
  },
  reportsEntryPage: function() {
    var parameterVals = window.location.search;
    localStorage.setItem('reportsEntryURL', parameterVals);
    $('.clear-button').on('click', function () {
      localStorage.removeItem('reportsEntryURL');
    });
  },
  reportsHairPage: function() {
    var parameterVals = window.location.search;
    localStorage.setItem('reportsHairURL', parameterVals);
    $('.clear-button').on('click', function () {
      localStorage.removeItem('reportsHairURL');
    });
  },
  reportsProgramPage: function() {
    var parameterVals = window.location.search;
    localStorage.setItem('reportsProgramURL', parameterVals);
    $('.clear-button').on('click', function () {
      localStorage.removeItem('reportsProgramURL');
    });
  },
  financePage: function() {
    var parameterVals = window.location.search;
    localStorage.setItem('financeURL', parameterVals);
    $('.clear-button').on('click', function () {
      localStorage.removeItem('financeURL');
    });
  },
  financePageBatch: function() {
    var parameterVals = window.location.search;
    localStorage.setItem('financeURLBatch', parameterVals);
    $('.clear-button').on('click', function () {
      localStorage.removeItem('financeURLBatch');
    });
  },
  financePageBatchManagement: function() {
    var parameterVals = window.location.search;
    localStorage.setItem('financeURLBatchManagement', parameterVals);
    $('.clear-button').on('click', function () {
      localStorage.removeItem('financeURLBatchManagement');
    });
  },
  financeReportsPage: function() {
    var parameterVals = window.location.search;
    localStorage.setItem('financeReportsURL', parameterVals);
    $('.clear-button').on('click', function () {
      localStorage.removeItem('financeReportsURL');
    });
    $('.dont-save-button').on('click', function () {
        localStorage.removeItem('financeReportsURL');
    });
  },
  financeReportsPageAR: function() {
    var parameterVals = window.location.search;
    localStorage.setItem('financeReportsURLAR', parameterVals);
    $('.clear-button').on('click', function () {
      localStorage.removeItem('financeReportsURLAR');
    });
  },
}