EMMY.searchAdmin = {
  init: function () {
    if($('#searchPage').val() != undefined) {
      var parameterVals = window.location.search;
      localStorage.setItem('searchURL', parameterVals);
      $('.clear-button').on('click', function () {
        localStorage.removeItem('searchURL');
      });
        // EMMY.searchAdmin.changeMass();
        EMMY.searchAdmin.submitterAutocomplete();
        EMMY.searchAdmin.setOptions();
    }

    var inputWidth = $('.entries-table').width();

    $('select').on('select2:open', function(e) {
      $(this).closest('.choices-filter-group').find('.selection').addClass('rad-dropSelect');
      if($(this).attr('id') == 'categories') {
        $('.searchAdmin').addClass('fullWidthSearch');
        $('.fullWidthSearch .rad-dropSelect').css('width', inputWidth);
        $('.fullWidthSearch.reportProofHairMakeup .rad-dropSelect').css('width', 600);
        $('.fullWidthSearch > .select2-container .select2-dropdown').css('width', inputWidth);
      }
    });
    $('select').on('select2:close', function(e) {
      $(this).closest('.choices-filter-group').find('.selection').removeClass('rad-dropSelect');
      if($(this).attr('id') == 'categories') {
        $('.searchAdmin').removeClass('fullWidthSearch');
      }
    });

      $('.choices-filter-group select').each(function () {
        var selectLabel = $(this).closest('.choices-filter-group').find('h4').text();
        $(this).closest('.choices-filter-group').find('.dropdown-wrapper').text(selectLabel);

      var filterGrouping = 'filter-tags-' + $(this).attr('id');
      var that = $(this);
      _setOptionasButton(that, filterGrouping);
      $(this).on('change', function (e) {
        var that2 = $(this);
        _setOptionasButton(that, filterGrouping);
      });
      $('body').on('click', '.name2', function(e) {
        var valueSelected = this.getAttribute('data-filter');
        _removeItem(that, valueSelected);
        $(this).remove();
      });
    });
    $('#changeEnMasse').on('click', function(e) {
        formData = $('#resultsForm,#searchForm').serialize();
        var changeMassButton = $(this);
        changeMassButton.attr('disabled', true);
        $.ajax({
            url: '/api/search/changeenmasse/process',
            method: 'POST',
            type: 'post',
            data: formData,
            success: function (data) {
                message = "Success: "+data.numAffected +" entries updated.<br/>";
                if(data.numSkipped) {
                  message += data.numSkipped +" non-text fields skipped <br/>";
                }
                message += "<a href='/search/changeenmasse/report/"+data.fileName+"'>Download change report</a>";
                $('#changeResults').html(message);
                EMMY.searchAdmin.changeMassChanged = true;
                changeMassButton.attr('disabled', false);
            },
            error: function() {
                $('#changeResults').html("Error, unable to apply changes");
                EMMY.searchAdmin.changeMassChanged = false;
                changeMassButton.attr('disabled', false);
            }
        });
    });

    if($('#search-page').val() != undefined) {
      // EMMY.searchAdmin.changeMass();
      EMMY.searchAdmin.submitterAutocomplete();
      EMMY.searchAdmin.setOptions();

    }

    $('#fieldChangeSelect').on('change', function(e) {
        EMMY.searchAdmin.setOptions();
    });
    $('#years').on('change', function(e) {
      var year = this.options[e.target.selectedIndex].text;
      window.location.href = window.location.pathname + "?years[]="+year;
    });


    $('#assetFiltersAll').on('change', function() {
      var checked = this.checked;
      $('.assetFilters').prop('checked', checked);
    });

    $('.downloadAsset').on('click', function(e) {
      var id = $(this).data('cfvid');
      var self = this;
      $.ajax({
        url: '/api/upload/updateLastViewed/'+id,
        method: 'POST',
        type: 'post',
        success: function (data) {
          //clear red thing
          $(self).closest('.upload-block').find('.blocktype').removeClass('updated');
            //.find('.blocktype'));
        },
        error: function() {

        }
      });
    })

  },
  submitterAutocomplete: function() {
      console.log('loading auto');
      var FORM = $('#resultsForm');
      var VALUE = FORM.find('input[name=userValue]');
      console.log(VALUE);
      $('#autocomplete').autocomplete({
          serviceUrl: '/api/entry/users/suggestions',
          minChars: 3,
          onSelect: function(suggestion) {
              VALUE.val(suggestion.data);
          }
      });

  },
  setOptions: function() {
      $('.cem-change-option').each(function(item) {
        var selectedFieldType = $('#fieldChangeSelect option:selected').val();
        $(this).addClass('cem-default-state');
        if($(this).hasClass('cem-'+selectedFieldType)) {
            $(this).removeClass('cem-default-state');
        }
      });
  }

};

function _setOptionasButton(that, filterGrouping) {
  var optionSelected = that.find("option:selected");
  $('.search-filters-added .' + filterGrouping).html('');
  for (var i = 0; i < optionSelected.length; i++) {
    var valueSelected = optionSelected[i].value;
    var textSelected = optionSelected[i].text;
    if ((valueSelected != undefined) && (valueSelected != '')) {
      var div = document.createElement('span');
      div.innerText = textSelected;
      div.className = 'name2';
      div.setAttribute('data-filter', valueSelected);
      var searchText = document.querySelector('.search-filters-added .'+filterGrouping);
      searchText.appendChild(div);
    }
  }
  if((optionSelected.val() == undefined) || (optionSelected.val() == '')) {
    $('.search-filters-added .'+filterGrouping).parent().find('label').addClass('hide-me');
  }
}

function _removeItem(selectList, idToRemove) {
  var values = selectList.val();
  if (values) {
    if (typeof(values) == 'object') {
      var i = values.indexOf(idToRemove);
      if (i >= 0) {
        values.splice(i, 1);
        if (values.length != 0) {
          selectList.val(values).change();
        } else {
          selectList.val([]).change();
        }
      }
    } else {
      if(values == null || values == '')
        selectList.val([]).change();
    }
  }
}
