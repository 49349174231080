EMMY.reportProofProgram= {
  init: function () {
    EMMY.reportProofProgram.reportTabs();

    var inputWidth = $('.entries-table').width();

    $('select').on('select2:open', function(e) {
      $(this).closest('.choices-filter-group').find('.selection').addClass('rad-dropSelect');
      if($(this).attr('id') == 'categories') {
        $('.reportProofProgram').addClass('fullWidthSearch');
        $('.fullWidthSearch .rad-dropSelect').css('width', inputWidth);
        $('.fullWidthSearch > .select2-container .select2-dropdown').css('width', inputWidth);
      }
    });
    $('select').on('select2:close', function(e) {
      $(this).closest('.choices-filter-group').find('.selection').removeClass('rad-dropSelect');
      if($(this).attr('id') == 'categories') {
        $('.reportProofProgram').removeClass('fullWidthSearch');
      }
    });

    $('.choices-filter-group select').each(function () {
      var selectLabel = $(this).closest('.choices-filter-group').find('h4').text();
      $(this).closest('.choices-filter-group').find('.dropdown-wrapper').text(selectLabel);

      var filterGrouping = 'filter-tags-' + $(this).attr('id');
      var that = $(this);
      _setOptionasButton(that, filterGrouping);
      $(this).on('change', function (e) {
        var that2 = $(this);
        _setOptionasButton(that, filterGrouping);
      });
      $('body').on('click', '.name2', function(e) {
        var valueSelected = this.getAttribute('data-filter');
        _removeItem(that, valueSelected);
        $(this).remove();
      });
    });
  },

  reportTabs: function() {
    $('.accordion-tabs').each(function(index) {
      if (window.location.hash) {
        var hash = $.trim(window.location.hash);
        $(hash).addClass('is-active').next().addClass('is-open').show();
      } else {
        $(this).children('li').first().children('a').addClass('is-active').next().addClass('is-open').show();
      }
      //$(this).children('li').first().children('a').addClass('is-active').next().addClass('is-open').show();
    });
    $('.accordion-tabs').on('click', 'li > a.tab-link', function(event) {
      if (!$(this).hasClass('is-active')) {
        event.preventDefault();

        var accordionTabs = $(this).closest('.accordion-tabs');
        accordionTabs.find('.is-open').removeClass('is-open').hide();

        $(this).next().toggleClass('is-open').toggle();
        accordionTabs.find('.is-active').removeClass('is-active');
        $(this).addClass('is-active');
      } else {
        event.preventDefault();
      }
    });
  },
};

function _setOptionasButton(that, filterGrouping) {
  var optionSelected = that.find("option:selected");
  $('.search-filters-added .' + filterGrouping).html('');
  for (var i = 0; i < optionSelected.length; i++) {
    var valueSelected = optionSelected[i].value;
    var textSelected = optionSelected[i].text;
    if (valueSelected != undefined) {
      var div = document.createElement('span');
      div.innerText = textSelected;
      div.className = 'name2';
      div.setAttribute('data-filter', valueSelected);
      var searchText = document.querySelector('.search-filters-added .'+filterGrouping);
      searchText.appendChild(div);
    }
  }
}

function _removeItem(selectList, idToRemove) {
  var values = selectList.val();
  if (values) {
    var i = values.indexOf(idToRemove);
    if (i >= 0) {
      values.splice(i, 1);
      if(values.length != 0) {
        selectList.val(values).change();
      } else {
        selectList.val('').change();
      }
    }
  }
}
