function countWords(s){
  s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
  s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
  return s.split(' ').length;
}

var customFieldIdHolder = false;

//This is the functionality for the entrant
EMMY.entrants = {
  init: function () {
    // console.log('entrants loaded');
    EMMY.entrants.activeFormAjaxCall = false;
    Dropzone.autoDiscover = false;
    EMMY.entrants.minRequired = ['.form-control.firstname', '.form-control.lastname', '.form-control.email'];
    EMMY.entrants.entryId = document.getElementById('entryId').innerText;
    if (document.getElementById('sourceId') != null) {
      EMMY.sourceId = document.getElementById('sourceId').innerText;
    } else {
      EMMY.sourceId = EMMY.entrants.entryId;
    }
    EMMY.entrants.ajaxBlurActiveSave = true;

    EMMY.entrants.bindNewUploadFields();
    EMMY.entrants.emailLink();
    EMMY.entrants.fullPath = '';
    EMMY.entrants.fullResizePath = '';
    EMMY.entrants.cropper;
    EMMY.entrants.btnView;
    EMMY.entrants.file = [];
    EMMY.entrants.s3bucket;

  },

  emailLink: function () {
    if ($('body').hasClass('entryAdmin')) {
      $('input.email').each(function () {
        var email = $(this).val().trim();

        if (email.length > 0) {
          var emailLink = '<a href="mailto:' + email
            + '" class="email-link"><i class="fa fa-envelope" aria-hidden="true"></i></a>';

          $(this).prev().append(emailLink);
        }
      });
    }
  },
  bindNewUploadFields: function () {
    dzOptions = {
      previewTemplate: "<div class=\"dz-preview dz-file-preview\"> " +
      "<div class=\"dz-image\"><img data-dz-thumbnail /></div>" +
      "<div class=\"dz-details\">" +
        "<div class=\"dz-filename\"><span data-dz-name></span></div> <div class=\"btn-remove file-remove\">Delete</div>" +
      "</div>\n  <div class=\"dz-progress\" ><span class=\"dz-upload\" data-dz-uploadprogress ></span></div>" +
      "<div class=\"dz-error-message\"><span data-dz-errormessage></span></div>" +
      "<div class=\"dz-success-mark\">" +
      "<svg width=\"54px\" height=\"54px\" viewBox=\"0 0 54 54\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" xmlns:sketch=\"http://www.bohemiancoding.com/sketch/ns\">" +
      "<title>Check</title>\n      <defs></defs>" +
      "<g id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\" sketch:type=\"MSPage\">" +
      "<path d=\"M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z\" id=\"Oval-2\" stroke-opacity=\"0.198794158\" stroke=\"#747474\" fill-opacity=\"0.816519475\" fill=\"#FFFFFF\" sketch:type=\"MSShapeGroup\"></path>" +
      "</g>" +
      "</svg>" +
      "</div>" +
      "<div class=\"dz-error-mark\">" +
      "<svg width=\"54px\" height=\"54px\" viewBox=\"0 0 54 54\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" xmlns:sketch=\"http://www.bohemiancoding.com/sketch/ns\">" +
      "<title>Error</title><defs></defs>" +
      "<g id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\" sketch:type=\"MSPage\">" +
      "<g id=\"Check-+-Oval-2\" sketch:type=\"MSLayerGroup\" stroke=\"#747474\" stroke-opacity=\"0.198794158\" fill=\"#FFFFFF\" fill-opacity=\"0.816519475\">" +
      "<path d=\"M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z\" id=\"Oval-2\" sketch:type=\"MSShapeGroup\"></path>" +
      "</g></g></svg></div></div>",
      maxFiles: 1,
      maxFilesize: 4096,
      timeout: 36000000,
      uploadMultiple: false,
      acceptedFiles: "image/*, application/pdf, video/*, application/zip, .txt, .csv, .doc, .docx, .xls, .xlsx",
      addRemoveLinks: true,
      dictRemoveFile: "Delete",
      // dictRemoveFileConfirmation: "Are you sure you wish to delete the file?",
      dictDefaultMessage: "<i class='fa fa-upload'></i>Upload",
      //custom props
      ZisImageType: true,
      thumbnailWidth: null,
      thumbnailHeight: 250,
      resizeQuality: 100,
      sending: _dropzoneSendingCallback,
      init: function () {
        var self = this;
        this.on("maxfilesexceeded", function (file) {
          this.removeAllFiles();
          this.addFile(file);
        });

        var self = this;
        /*determines if this uploader is a type that uses the cropper*/
        this.isCropperImageType = function() {
          return (this.options.uploadType == 'headshot' || this.options.uploadType == 'portrait' );
        };
        this.on("thumbnail", function (file) {
            if (file.accepted) {
              return;
            }
            var minWidth = this.element.getAttribute('data-min-width') ? Number(this.element.getAttribute('data-min-width')) : null;
            var minHeight = this.element.getAttribute('data-min-height') ? Number(this.element.getAttribute('data-min-height')) : null;
            var aspectRatio = this.element.getAttribute('data-aspect-ratio') ? Number(this.element.getAttribute('data-aspect-ratio')) : null;
            if ((minWidth && file.width < minWidth) || (minHeight && file.height < minHeight)) {
              file.rejectDimensions(minHeight, minWidth, aspectRatio, file.height, file.width);
              EMMY.entrants.rejectDimensions = true;
              // console.log('rejecting file')
              $(this.element).find('.requiredFile').val("");
            }
            else {
              EMMY.entrants.rejectDimensions = false;
              file.acceptDimensions();
            }
          },
          this.on("addedfile", function (file) {

              if (this.files.length > 1) {
                //replace existing file
                this.removeFile(this.files[0]);
              }
              //Dropzone thumbnail stalls on larger images so we'll just skip that for images >8MB
              if(file.size > 8000000) {
                this.options.createImageThumbnails = false;
                this.options.ZisImageType = false;
              } 
              //override the url for Entrants (get the entrantId dynamically)
              if ($(this.element).closest('.entrant-item').length > 0) {
                sourceId = $(this.element).closest('.entrant-item').find('.entrantIdVal').val();
                if (sourceId) {
                  var customFieldId = Number(this.element.getAttribute('data-value'));

                  if (uploadType == 'headshot' || uploadType == 'portrait' ) {
                    this.options.url = "/api/upload/raw";
                  } else if (uploadType == 'video') {
                    //this gets set in the signature method
                  } else {
                    this.options.url = "/api/uploadFile/" + sourceId + "/" + customFieldId;
                  }
                } else {
                  this.removeFile(file);
                }
              }

              var btnRemove = file.previewElement.querySelector('.btn-remove');
              btnRemove.fileRef = file;
              btnRemove.addEventListener('click', function(e) {
                var button = this;
                $('#fileDeleteModal').iziModal('open');
                customFieldIdHolder = Number($(this).closest('.form-control').data('value'));
                $('#modal-fileYesDelete').on('click', function() {
                  dz.removeFile(button.fileRef);
                });
                $('#modal-fileCancelDelete').on('click', function() {
                  $('#fileDeleteModal').iziModal('close');
                });
              })
              $(self.element).closest('.form-group-beside').find('.dz-message').hide();

              /* Maybe display some more file information on your page */
            },
            this.on("removedfile", function (file) {
                var customFieldId = customFieldIdHolder;
                var sourceId = EMMY.entrants.entryId;
                var token = $('input[name=upload_link_token]').val();
                var path = "/api/uploadFile/" + sourceId + "/" + customFieldId;
                if (typeof(token) == 'undefined') {
                  console.log('there is no token ');

                } else {
                  path = "/api/uploadFileThirdParty/" + token;
                  console.log('token is ',token);
                }
                console.log(path);

                if ($('.file-upload-item-' + customFieldIdHolder).closest('.entrant-item').length > 0) {
                  sourceId = $('.file-upload-item-' + customFieldIdHolder).closest('.entrant-item').find('.entrantIdVal').val();
                }
                $('.file-upload-item-' + customFieldIdHolder).closest('.form-group-beside').find('.btn-view').hide();
                if (sourceId && customFieldId) {
                  $.ajaxSetup({
                    headers: {
                      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                  });
                  $.ajax({
                    url: path,
                    type: "DELETE",
                    complete: function() {
                      window.location.reload();
                    }
                  });
                }
                $('.file-upload-item-' + customFieldIdHolder).find('.requiredFile').val("");
                $('.file-upload-item-' + customFieldIdHolder).find('.dz-message').css('display','block');
                $('#fileDeleteModal').iziModal('close');
              }
            ),

            this.on('uploadprogress', function (file, progress) {
              window.onbeforeunload = function() {
                return true;
              };
            }),

            this.on('complete', function (file) {
              window.onbeforeunload = null;
              var minWidth = this.element.getAttribute('data-min-width') ? Number(this.element.getAttribute('data-min-width')) : null;
              var minHeight = this.element.getAttribute('data-min-height') ? Number(this.element.getAttribute('data-min-height')) : null;

              var cropper;
              var customFieldId = Number(this.element.getAttribute('data-value'));
              var S3_url = this.element.getAttribute('data-url');
              var sourceId = EMMY.entrants.entryId;
              EMMY.entrants.btnView = $(self.element).closest('.form-group-beside').find('.btn-view');
              $(self.element).closest('.form-group-beside').find('.dz-message').hide();
              if ($(this.element).closest('.entrant-item').length > 0) {
                sourceId = $(this.element).closest('.entrant-item').find('.entrantIdVal').val();
              }

              if (uploadType == 'headshot' || uploadType == 'portrait') {
                if (file.mockFile == null) { // din't run for images set programatically (initial page load)
                  //don't open the cropper for invalid images
                  if ((minWidth && file.width < minWidth) || (minHeight && file.height < minHeight)) {
                      return;
                  } else if ((minWidth && file.width <= minWidth) && (minHeight && file.height <= minHeight)) {
                    //if we have an image with the exact dimensions, don't open the cropper
                    cropData = {x: 0, y: 0, width:1200, height: 1500};
                    // console.log('uploading');
                    saveCroppedImage(cropData, file, self, sourceId, customFieldId);
                    return;
                  }
                  $('#cropModal').iziModal('open');
                  $("#imageCrop").attr("src", EMMY.entrants.resizePath);
                  var image = document.getElementById('imageCrop');
                  $image = $("#imageCrop");
                  if (EMMY.entrants.cropper) {
                    EMMY.entrants.cropper.destroy();
                  };

                  var lastValidCrop = null;

                  // console.log(file);

                  cropper = new Cropper(image, {
                    viewMode: 3,
                    zoomable: false,
                    crop: function (e) {
                      // console.log(e.detail);
                      var validCrop = true;
                      cData = cropper.getCanvasData();
                      /*
                       file 2400x3000 = 1200x1500 = 1200x1500
                       file 4800x6000 = 1200x1500 = 600x750
                       file 6000x6000 = 1500x1500 = 600x750
                       file 13500x6750 = 1200x600 = 213x266
                       */
                      var minCropWidth = minWidth / file.width * cData.naturalWidth;
                      var minCropHeight = (minWidth * 1.25) / file.height * cData.naturalHeight;

                      if (Math.round(e.detail.width*1000) < Math.round(minCropWidth*1000)) validCrop = false;
                      if (Math.round(e.detail.height*1000) < Math.round(minCropHeight*1000)) validCrop = false;

                      if (validCrop) {
                        lastValidCrop = cropper.getData();
                        lastCropCount = 0;
                      } else {
                        if (lastValidCrop)
                          cropper.setData({x: lastValidCrop.x, y: lastValidCrop.y, width: minCropWidth, height: minCropHeight});
                        else {
                          cropper.setData({x: 0, y: 0, width: minCropWidth, height: minCropHeight});
                        }
                      }

                    },
                    ready: function () {
                      cropper.setData({x: 0, y: 0, width: 100000, height: 10000});
                      if (file.width <= minWidth && file.height <= minHeight) {
                        $('#modal-saveButton').trigger('click');
                      }
                    }
                  });


                  cropper.setAspectRatio(0.8);
                  EMMY.entrants.cropper = cropper;

                  $('#modal-saveButton').unbind('click');

                  $(document).on('click', '#modal-saveButton', function (ev) {
                    $(this.element).closest('.form-group-beside').find('.btn-view').hide();
                    var cropData = cropper.getData(true);
                    saveCroppedImage(cropData, file, self, sourceId, customFieldId);
                    //set field validator passed.
                    $(self.element).find('.requiredFile').val(file.name);

                  });
                  $('#modal-cancelButton').unbind('click');
                  $(document).on('click', '#modal-cancelButton', function (ev) {
                    //self.emit("thumbnail", file, filePath);
                    self.removeAllFiles();
                    $('#cropModal').iziModal('close');
                  });
                  $('.iziModal-content .modal-close').on('click', function (e) {
                    self.removeAllFiles();
                  });
                }
              } else if(uploadType == 'video') {


                if(EMMY.entrants.file.key) {
                  S3_url =  this.options.url + EMMY.entrants.file.key;
                  this.element.setAttribute('data-url', S3_url)
                }

                if(S3_url !== undefined && S3_url !== null &&  S3_url != "") {
                  EMMY.entrants.btnView.attr('href', S3_url);
                  EMMY.entrants.btnView.show();
                }
                
                EMMY.entrants.btnView.attr('download', file.name);
              }
              uploadType = this.options.uploadType;
              if (uploadType == 'headshot' || uploadType == 'portrait' || uploadType == 'logo' || uploadType == 'image') {
                this.options.ZisImageType = true;
                this.options.createImageThumbnails = true;
              }
            }),
            this.on('error', function(file, errorMessage) {
              window.onbeforeunload = null;
              if (errorMessage.indexOf('minimum') == -1) {
                var errorDisplay = document.querySelectorAll('[data-dz-errormessage]');
                  errorDisplay[errorDisplay.length - 1].innerHTML = 'Error with file upload. Please try again.';
              }
              this.options.createImageThumbnails = true;
              this.options.ZisImageType = true;
            })
          ));
      },
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },



      accept: function (file, done) {

      if (this.options.uploadType == 'video') {
        file.postData = [];
        var customFieldId = Number(this.element.getAttribute('data-value'));
        var self = this;
        $.ajax({
          url: '/api/upload/getS3Signature',
          data: {
            name: file.name,
            type: file.type,
            size: file.size,
            customFieldId: customFieldId,
            sourceId: EMMY.sourceId
          },
          type: 'POST',
          success: function jQAjaxSuccess(response) {
            //response = JSON.parse(response);
            //file.custom_status = 'ready';
            EMMY.entrants.file = response.s3_request_fields;
            self.options.url = response.s3_url;
            done();
          },
          error: function (response) {
            file.custom_status = 'rejected';
            done('Error preparing the upload');
          }
        });
      } else {
        if (!this.options.ZisImageType) {
          done();
          return;
        }


        if (file.type.indexOf('image') < 0) { //dont check dimensions for non-images
          file.acceptDimensions = done;
          file.rejectDimensions = done;
        } else {
          file.acceptDimensions = done;
          file.rejectDimensions = function (minHeight, minWidth, aspectRatio, fileHeight, fileWidth) {
            done("Error: Image must be minimum " + minWidth + 'x' + minHeight + "" +
              ", selected image is " + fileWidth + "x" + fileHeight);
          };
        }

      }
    },


      success: function (file, response) {
        $('#timestamp').text(response.entry_updated);
        file.name = response.fileName;
        EMMY.entrants.fullPath = response.full_path;
        EMMY.entrants.resizePath = response.resize_path;
        //for anything that uses the cropper, don't set the field as filled in just yet.
        if (!this.isCropperImageType()) {
          $(this.element).find('.requiredFile').val(file.name);
        }
        $('.email-send-message.anonymous').css('opacity', 1);
      },

    };


    var allDropzones = document.querySelectorAll('.dropzone:not(.dz-clickable)');
    for (var i = 0, len = allDropzones.length; i < len; i++) {
      var fieldObject = allDropzones[i];
      var customFieldId = Number(fieldObject.getAttribute('data-value'));
      var fileName = fieldObject.getAttribute('data-filename') ? fieldObject.getAttribute('data-filename') : null;
      var filePath = fieldObject.getAttribute('data-filepath') ? fieldObject.getAttribute('data-filepath') : null;
      var url = fieldObject.getAttribute('data-url') ? fieldObject.getAttribute('data-url') : null;
      var fileExtensions = fieldObject.getAttribute('data-extensions-whitelist') ? fieldObject.getAttribute('data-extensions-whitelist') : null;
      var uploadType = fieldObject.getAttribute('data-upload-type') ? fieldObject.getAttribute('data-upload-type').toLowerCase() : null;
      // makes the field item clickable
      dzOptions.clickable = '.file-upload-item-'+customFieldId+' .dropzone';

      var localOptions = {
        url: "/api/uploadFile/" + EMMY.entrants.entryId + "/" + customFieldId
      };

      if (uploadType == 'headshot' || uploadType == 'portrait' ) {
        localOptions.url = "/api/upload/raw"
      } else if (uploadType == 'video') {
        //this gets set in the signture method
      }

      if (fileExtensions) {
        localOptions.acceptedFiles = fileExtensions;
      }
      if (uploadType != 'headshot' && uploadType != 'portrait' && uploadType != 'logo' && uploadType != 'image') {
        localOptions.createImageThumbnails = false;
        localOptions.ZisImageType = false;
      }
      localOptions.uploadType = uploadType;


      var dz = new Dropzone(fieldObject, $.extend({}, dzOptions, localOptions));

      if (filePath || url) {
        var mockFile = {
          name: fileName,
          size: 100000,
          accepted: true,
          mockFile: true
        };
        dz.emit("addedfile", mockFile);
        dz.files.push(mockFile);

        // And optionally show the thumbnail of the file:
        dz.createThumbnailFromUrl(mockFile, filePath, function () {
          dz.emit("complete", mockFile);
        });
        // And optionally show the thumbnail of the file:
        if(uploadType == 'headshot' || uploadType == 'portrait' || uploadType == 'image' || uploadType == 'logo') {
          dz.emit("thumbnail", mockFile, filePath);
        }

        //force complete class to remove the progress bar on load. should figure out why some euploads aren't "complete"
        $(fieldObject).find('.dz-preview').addClass('dz-complete');
      }

    }
  }
}


  function _dropzoneSendingCallback(file, xhr, formData) {
  $.each(EMMY.entrants.file, function(k, v) {
      formData.append(k, v);
    });
    var token = $('input[name=upload_link_token]').val();
    if (typeof(token) != "undefined") {
      formData.append('token', token);
    }
  };

function saveCroppedImage(cropData, file, self, sourceId, customFieldId) {
  $.ajax('/api/saveCroppedFile/' + sourceId + "/" + customFieldId, {
    method: "POST",
    timeout: 300000,
    data: cropData,
    success: function (response) {
      var croppedPath = response.croppedPath;
      self.emit("thumbnail", file, croppedPath);
      EMMY.entrants.btnView.show();
      EMMY.entrants.btnView.attr('href', response.croppedPath);
      EMMY.entrants.btnView.attr('download', file.name);
      $('#cropModal').iziModal('close');
    },
    error: function () {
      console.log('Upload error');
    }
  });
}
