EMMY.thirdPartyFacultySave = {
    init: function () {
        EMMY.entrants.entryId = document.getElementById('entryId').innerText;
        EMMY.blurSave.activeAjaxUpdate = true;
        let formID = $('#entry-fulldetails-form');
        formID.find('.form-control-thirdparty').on('change', function (e) {
            var dataForm = formID.serialize();
            $.ajax({
                url: '/api/uploadFileFacultyFields/' + EMMY.entrants.entryId,
                type: 'POST',
                data: dataForm,
                success: function (data) {
                    console.log('blur saved');
                }
            });
        });
    }
}