EMMY.summary = {
  init: function() {
    var entrantListing = document.querySelectorAll('.entrant-listing li');
    Array.from(entrantListing).forEach(function(entrantItem) {
      entrantItem.addEventListener('click', function (e) {
        var removeActiveListingItem = document.querySelectorAll('.entrant-listing-item.active');
        Array.from(removeActiveListingItem).forEach(function(listingItem) {
          listingItem.classList.remove('active');
        });
        var activeEntrantNum = this.getAttribute('data-entrant-num');
        $('.entrant-listing-item-' + activeEntrantNum).addClass('active');
      });
    });
  }
};