EMMY.noticesAlerts = {
  init: function () {
    // add button clicked
    $('#add-notice').click(function (e) {
      e.preventDefault()
      $('#modal-new-notice').show()
    })

    // modal cancel clicked
    $('#cancel-notice').click(function (e) {
      e.preventDefault();
      $(e.target).closest('form').find('.trumbowyg-editor').html('');
      $(e.target).closest('form')[0].reset();
      $('#modal-new-notice').hide()
    })

    // save notice button clicked
    $('#save-new-notice').click(function (e) {
      $('#new-notice-form').validate({
        submitHandler: function (form) {
          $.post('/notices_alerts/new', $(form).serializeArray(), function () {
            EMMY.noticesAlerts.refreshNoticesList()
            EMMY.noticesAlerts.clearModal()
            $('#modal-new-notice').hide()
          })
        }
      })
    })

    EMMY.noticesAlerts.initRowButtons()
  },
  initRowButtons: function () {

    // edit notice clicked
    $('[id^=edit-notice-]').each(function () {
      this.onclick = function (e) {
        e.preventDefault()
        const id = $(this).attr('data-notice-idx')

        $('[id^=notice-row-]').show()
        $('[id^=notice-form-row-]').hide()
        $('#notice-row-' + id).hide()
        $('#notice-form-row-' + id).show()
      }
    })

    // delete notice clicked
    $('[id^=delete-notice-]').each(function () {
      this.onclick = function (e) {
        e.preventDefault()
        const id = $(this).attr('data-notice-idx')
        $.post('/notices_alerts/delete/' + id, function () {
          EMMY.noticesAlerts.refreshNoticesList()
        })
      }
    })

    // save notice clicked
    $('[id^=save-notice-]').each(function () {
      this.onclick = function (e) {
        const id = $(this).attr('data-notice-idx')
        const formId = '#notice-form-'+id
        $(formId).validate({
          ignore: 'hidden',
          submitHandler: function (form) {
            $.post('/notices_alerts/update/'+id, $(form).serializeArray(), function () {
              window.location.reload(true);
              return;
              EMMY.noticesAlerts.refreshNoticesList()
            })
          }
        })
      }
    })

    // cancel notice clicked
    $('[id^=cancel-notice-]').each(function () {
      this.onclick = function (e) {
        e.preventDefault()
        const id = $(this).attr('data-notice-idx')
        $('[id^=notice-row-]').show()
        $('[id^=notice-form-row-]').hide()
        var noticeText = $('[id^=notice-row-' + id + ']').find('.notice-text').html();
        $(this).closest('tr').find('.trumbowyg-editor').html(noticeText);
        $(this).closest('form')[0].reset();
      }
    })
  },

  refreshNoticesList: function () {
    window.location.reload(true);
    return;
  },
  clearModal: function () {
    $('#new-notice-form').trigger('reset')
  }
}