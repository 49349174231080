EMMY.userAdmin = {
    init: function () {
        EMMY.userAdmin.submitterAutocomplete();

    },
    submitterAutocomplete: function() {

        $('#user-admin-autocomplete').autocomplete({
            serviceUrl: '/api/entry/users/suggestions',
            minChars: 3,
            onSelect: function(suggestion) {

                // Redirect to admin View.
                window.location.href = '/user/' + suggestion.data + '/edit';

            }
        });
    }
}