//This is the functionality for the entrant
EMMY.programSort = {
  init: function () {
    EMMY.programSort.sortCloneReplacement();
    EMMY.programSort.removeReplacement();
    EMMY.programSort.sorttoJson();
    EMMY.programSort.hideTrash();
    if ($('#programTextArea').val()) {
      EMMY.programSort.unwindSort();
    }
  },
  sortCloneReplacement: function() {
    var addMoreSort = document.querySelector('.moreSort');
    addMoreSort.addEventListener('click', function(e) {
      e.preventDefault();
      _doActualCloning();
      EMMY.programSort.removeReplacement();
      EMMY.programSort.sorttoJson();
      EMMY.programSort.hideTrash();
    });
    // EMMY.programSort.removeTeamMember();
  },
  removeReplacement: function(form) {
    $('.trash-sort').on('click', function(e) {
      e.preventDefault();
      if ($('#allKeyValues .sort_item').length > 1) {
        $(this).closest('.sort_item').remove();
        EMMY.programSort.hideTrash();
        _createSortsJson(this);
      }
    });
  },
  sorttoJson: function() {
    $('.sort_item input').on('blur', function() {
      _createSortsJson(this);
      // EMMY.programSort.saveEntrantData();
    });
  },
  hideTrash: function() {
    var numSorts = $('#allKeyValues .sort_item').length;
    console.log(numSorts, 'num');
    if (numSorts == 1) {
      $('.sort_item .fa-trash').addClass('hide-me');
    } else {
      $('.sort_item .fa-trash').removeClass('hide-me');
    }
  },
  unwindSort: function() {
    var sortValues = $('#programTextArea').val();
    var jsonArray = JSON.parse(sortValues);
    for (i = 1; i < jsonArray.length; i++) {
      _doActualCloning();
    }
    EMMY.programSort.removeReplacement();
    EMMY.programSort.sorttoJson();
    EMMY.programSort.hideTrash();


    $.each(jsonArray, function(i,v) {
      console.log(i,v,'yes');
      for (key in v) {
        var valueKey = v[key];
        console.log(valueKey, v[key], 'eeee', key);
        $('.sort_item:eq('+i+') .sortKey').val(key);
        $('.sort_item:eq('+i+') .sortValue').val(valueKey);
      }
    });
  }
};

function _createSortsJson() {
  var jsonVal = new Array();
  $('#allKeyValues .sort_item').each(function(i,v) {
    var sortKey = $(this).find('.sortKey').val();
    var sortValue = $(this).find('.sortValue').val();
    if ((!sortKey) && (!sortValue)) {
      return;
    }
    var obj = {};
    obj[sortKey] = sortValue;
    jsonVal.push(obj);
  });
  document.getElementById('programTextArea').value = JSON.stringify(jsonVal);
}

function _doActualCloning() {
  var fullList = document.getElementById('allKeyValues');
  var itemToClone = document.querySelector('.sort_item');
  var cln = itemToClone.cloneNode(true);
  var spans = cln.querySelectorAll('input');
  Array.prototype.forEach.call(spans, function(el, i) {
    el.value = '';
  });
  fullList.appendChild(cln);
}