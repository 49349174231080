// This creates the link between the footer buttons and form buttons
// in the footer on the create entry pages

EMMY.footerLinks = {
  init: function() {
    
    var footerLinks = document.querySelectorAll('.footer-steps .btn:not(.btn-step-previous)');
    Array.from(footerLinks).forEach(function(link) {
      link.addEventListener('click', function(event) {
        event.preventDefault();
        var target = event.target || event.srcElement;
        if (target.className.indexOf('step-previous-submitted-step') != -1) {
          var formSubmitter = document.getElementById('form-previously-submitted').className;
          document.getElementById('form-previously-submitted').className = 'border-red';
        } else if (target.className.indexOf('step-review-step') != -1) {
          var idClicked = this.id.replace('footer','');
          EMMY.entrants.ajaxSubmitEntrant(idClicked);
        } else {
          var idClicked = this.id.replace('footer','');
          EMMY.entrants.breakVal = 'true';
          EMMY.validateForm.checkRequired();
          if (EMMY.entrants.breakVal) {
            document.getElementById(idClicked).click();
          }

          // Don't need to validate cancelling anything
          if (this.className.indexOf('btn-cancel') != -1) {
            document.getElementById(idClicked).click();
          }
        }
      });
    });
  }
}
// This creates the link between the footer buttons and form buttons
// in the footer on the create entry pages
