EMMY.modals = {
  init: function() {
    EMMY.searchAdmin.changeMassChanged = false;
    var modalClick = $('.modal, .modal-second, .modal-third, .modal-four, .modal-five, .modal-six');
    var modalClickInner = document.querySelector('.modal-inner');
    var modal = document.querySelector('.modal-fade-screen');
    var modalClickClose = document.querySelector('.modal-close');
    EMMY.modals.bodyClass = document.querySelector('body');
      modalClick.on('click', function(e) {
        if (!$(this).hasClass('no-preventdefault')) {
          e.preventDefault();
        }
        $('.modal-inner').removeClass('showSuccess');
        EMMY.modals.escapeListen();
        EMMY.modals.secondChecker(this);
        EMMY.modals.previousFillCompetition(this);
        EMMY.modals.notSubmittedElsewhere(this);
        if (this.dataset.entries) {
          var entryId = this.dataset.entries;
          EMMY.modals.deleteAndSendMessage(entryId);
        }
        if (this.dataset.resendemail) {
          var origEmail = this.dataset.resendemail
          $('input[name="email"]').val(origEmail)
        }
        if (this.dataset.resend) {
          var entryId = this.dataset.resend;
          EMMY.modals.reportsResendEmail(entryId);
        }
        if($(this).hasClass('change-mass-button') && $(this).hasClass('add-check-button')) {
          amountDue = 0;
          orderIds = [];
          invoiceNum = [];
          var val = [];
          $('[type=checkbox]:checked').each(function(i){
            val[i] = $(this).val();
            amountDue += parseFloat($('[data-order="'+$(this).val()+'"]').data('amount'));
            orderIds.push($('[data-order="'+$(this).val()+'"]').data('order'));
            invoiceNum.push($('[data-order="'+$(this).val()+'"]').data('invoice'));
          });
          EMMY.modals.financeSaveCheck(orderIds.join(';'), invoiceNum.join(' | '), amountDue.toFixed(2));
        }
        if (this.dataset.order) {
          var orderId = this.dataset.order;
          var invoiceNum = this.dataset.invoice;
          var amountDue = this.dataset.amount;
          console.log(orderId + invoiceNum + ' '+amountDue);
          EMMY.modals.financeSaveCheck(orderId, invoiceNum, amountDue);
        }

        if (this.dataset.batches) {
          EMMY.modals.createBatch();
        }

        if (this.dataset.batchnumber) {
          EMMY.modals.updateBatch(this);
        }

        if (this.dataset.previewcatid) {
          var entryId = this.dataset.previewcatid;
          EMMY.modals.previewCategory(entryId);
        }

        if ($(this).attr('id') == 'messageSend') {
          $('textarea.message-input').focus();
        }
      });
      $('.modal-fade-screen').on('click', function(e) {
        if (EMMY.searchAdmin.changeMassChanged) {
          location.reload();
        }
        e.preventDefault();
        EMMY.modals.bodyClass.classList.toggle('modal-open');
      });
      $('.modal-fade-screen-second').on('click', function(e) {
        if (EMMY.searchAdmin.changeMassChanged) {
          location.reload();
        }
        e.preventDefault();
        EMMY.modals.bodyClass.classList.toggle('modal-open-second');
      });
      $('.modal-fade-screen-third').on('click', function(e) {
        e.preventDefault();
        EMMY.modals.bodyClass.classList.toggle('modal-open-third');
      });
      $('.modal-fade-screen-four').on('click', function(e) {
        e.preventDefault();
        EMMY.modals.bodyClass.classList.toggle('modal-open-four');
      });
      $('.modal-fade-screen-five').on('click', function(e) {
        e.preventDefault();
        EMMY.modals.bodyClass.classList.toggle('modal-open-five');
      });
      $('.modal-fade-screen-six').on('click', function(e) {
        e.preventDefault();
        EMMY.modals.bodyClass.classList.toggle('modal-open-six');
      });
      $('.modal-close').on('click', function(e) {
        // e.preventDefault();
        if (EMMY.searchAdmin.changeMassChanged) {
          location.reload();
        }
        EMMY.modals.bodyClassCloseModal();
        $('.messages-modal-content .alert').addClass('hide-me');
      });
    $('.modal-inner').on('click', function(event) {
      var target = event.target || event.srcElement;
      if (target.className.indexOf('softDeleteEntry') != -1) {
        $('.formactionval').val('cancel');
        document.getElementById('createEntryForm').submit();
      }
      if ((target.className.indexOf('entries-setDelete') != -1) && ($('.entries-table').val() == undefined) && ($('.checkout-table-wrap').val() == undefined)) {
        EMMY.modals.actualDeleteWork(EMMY.entrants.entryId);
      }
      if ((target.className.indexOf('submit-new-submitter') != -1) && ($('.entries-table').val() == undefined)) {
        EMMY.modals.processEntrySubmitterChange();
      }
      if ((target.className.indexOf('reassign-voucher-submit') != -1) && ($('.entries-table').val() == undefined)) {
        EMMY.modals.processReassignVoucher();
      }
      event.stopPropagation();
    });
    $('.modal-download').on('click', function(e) {

    });

  },
  secondChecker: function(link) {
    // console.log(link, 'test', $(link).hasClass('hide-automatically'));
    if ($(link).hasClass('modal')) {
      EMMY.modals.bodyClass.classList.toggle('modal-open');
    } else if ($(link).hasClass('modal-second')) {
      EMMY.modals.bodyClass.classList.toggle('modal-open-second');
    } else if ($(link).hasClass('modal-third')) {
      EMMY.modals.bodyClass.classList.toggle('modal-open-third');
    } else if ($(link).hasClass('modal-four')) {
      EMMY.modals.bodyClass.classList.toggle('modal-open-four');
      EMMY.ballotPreview.previewAjax();
    } else if ($(link).hasClass('modal-five')) {
      EMMY.modals.bodyClass.classList.toggle('modal-open-five');
    } else if ($(link).hasClass('modal-six')) {
      EMMY.modals.bodyClass.classList.toggle('modal-open-six');
    }

    if($(link).hasClass('hide-automatically')) {
      setTimeout(function() {
        $('body').removeClass('modal-open');
        $('body').removeClass('modal-open-second');
        $('body').removeClass('modal-open-third');
        $('body').removeClass('modal-open-four');
        $('body').removeClass('modal-open-five');
        $('body').removeClass('modal-open-six');
      }, 3000);
    }
  },
  bodyClassCloseModal: function() {
    if ($('body').hasClass('payment-page')) {
      $('#billing_contact').val('').trigger('change');
    }
    if ($('body').hasClass('modal-open')) {
      EMMY.modals.bodyClass.classList.toggle('modal-open');
    } else if ($('body').hasClass('modal-open-second')) {
      EMMY.modals.bodyClass.classList.toggle('modal-open-second');
    } else if ($('body').hasClass('modal-open-third')) {
      EMMY.modals.bodyClass.classList.toggle('modal-open-third');
    } else if ($('body').hasClass('modal-open-four')) {
      EMMY.modals.bodyClass.classList.toggle('modal-open-four');
    } else if ($('body').hasClass('modal-open-five')) {
      EMMY.modals.bodyClass.classList.toggle('modal-open-five');
    } else if ($('body').hasClass('modal-open-six')) {
      EMMY.modals.bodyClass.classList.toggle('modal-open-six');
    }
  },
  categorySelect: function() {
    var categorySelect = document.querySelectorAll('.catSelect');
    Array.from(categorySelect).forEach(function(link) {
      link.addEventListener('click', function (e) {
        e.preventDefault();
        var clickedCategoryValue = this.getAttribute('data-category-value');
        // console.log(clickedCategoryValue, 'this is the clicked cate')
        EMMY.selectList.init.createCategory.val(clickedCategoryValue).trigger("change");
        EMMY.modals.bodyClass.classList.toggle('modal-open');
        EMMY.category.changeCategory(clickedCategoryValue);
      });
    });
  },
  escapeListen: function() {
    if (!document.getElementById('previousPage')) {
      EMMY.modals.bodyClass.addEventListener('keyup', function (evt) {
        evt = evt || window.event;
        var isEscape = false;
        if ("key" in evt) {
          isEscape = (evt.key == "Escape" || evt.key == "Esc");
        } else {
          isEscape = (evt.keyCode == 27);
        }
        if (isEscape) {
          $('body').removeClass('modal-open');
          $('body').removeClass('modal-open-second');
          $('body').removeClass('modal-open-third');
          $('body').removeClass('modal-open-four');
          $('body').removeClass('modal-open-five');
          $('body').removeClass('modal-open-six');
        }
      });
    }
  },
  previousFillCompetition: function(clickedElement) {
    if (clickedElement.className.indexOf('btn-previous-cancel') != -1) {
      $('#eligibilty-denied').addClass('slide-in');
      $('#check-eligibility').addClass('slide-out');
      $('#modal-message').hide();
      $('.close-icon').hide();
    }
  },
  notSubmittedElsewhere: function(clickedElement) {
    if (clickedElement.className.indexOf('elsewhere-option') != -1) {
      // $('#eligibilty-ok').addClass('slide-in');
      // $('#check-eligibility').addClass('slide-out');
      $('#modal-message').hide();
      $('.close-icon').hide();
      document.getElementById('createEntryForm').submit();
    }
  },
  deleteAndSendMessage: function(entryId, justDelete) {
    var modal = document.querySelector('.modal-fade-screen');
    modal.setAttribute('entries_id', entryId);
    var deleteButton = document.querySelector('.entries-setDelete');
    deleteButton.addEventListener('click', function() {
      EMMY.modals.actualDeleteWork(entryId);
    });
  },
  actualDeleteWork: function(entryId) {
    EMMY.modals.entriesDeleteWork(entryId);
  },
  entriesDeleteWork: function(entryId) {
    var entryDeleteForm = document.getElementById('delete-entry-' + entryId);
    entryDeleteForm.submit();
  },
  checkoutDeleteWork: function(entryId) {
    var data = { _method: 'delete' };
    $.ajax({
      type: "POST",
      url: '/entry/' + entryId,
      data: data,
      success: function (data) {
        window.location = '/checkout';
      },
      error: function (data) {
        console.log("error saving batch");
      }
    });
  },
  processEntrySubmitterChange: function() {
      EMMY.entryAdmin.changeSubmitter();
      EMMY.modals.bodyClass.classList.toggle('modal-open');
  },
  processReassignVoucher: function() {
    EMMY.entryAdmin.reassignVoucherSubmit();
    //EMMY.modals.bodyClass.classList.toggle('modal-open');
    $('.modal-close').click();
  },
  reportsResendEmail: function(entryId) {
    var financeSaveCheckButton = document.querySelector('.resend-email-save');
    financeSaveCheckButton.addEventListener('click', function(e) {
      e.preventDefault();
      var emailVal = $(this).closest('form').find('input').val();
      if ($(this).hasClass('music-report')) {
        EMMY.modals.musicReportsResendAjax(entryId, emailVal);
      } else {
        EMMY.modals.hairReportsResendAjax(entryId, emailVal);
      }
    });
  },
  financeSaveCheck: function (orderId, invoiceNum, amountDue) {
    console.log('saving check '+orderId);
    $('#invoiceid-text').text(invoiceNum);
    $('input[name=amount]').val(amountDue);
    $('input[name=check]').val('');
    $('textarea[name=note]').val('');

    var modal = document.querySelector('.modal-fade-screen');

    $('.finance-check-save').one('click', function(e) {
      e.preventDefault();
      var data = $('form#finance-form-data').serialize();
      EMMY.modals.financeSubmitCheck(orderId, data);
    });
  },
  updateBatch: function(button) {
    var total = button.dataset.total;
    var batchnumber = button.dataset.batchnumber;
    var batchid = button.dataset.id;
    console.log(batchid);
    $('#batchtotal-text').text(total);
    $('#batchnumber-text').text(batchnumber);

    var createBatchClickSave = document.querySelector('.finance-batch-save');
    createBatchClickSave.addEventListener('click', function(e) {
      e.preventDefault();
      var data = $('form#finance-batch-form-data').serialize();
      EMMY.modals.updateBatchClick(batchid, data);
    });
  },
  updateBatchClick: function(batchid, data) {
    $.ajax({
      type: "POST",
      url: '/api/batch/update/'+batchid,
      data: data,
      dataType: 'json',
      success: function (data) {
        console.log('Batch has been sent');
        $('.modal-close').click();
      },
      error: function (data) {
        console.log("error saving batch");
      }
    });
  },
  createBatch: function() {
    var total = 0;
    var payment_ids = [];

    $("table#check-batch").html('');
    $(":checked").each(function(item){
      payment_ids.push(this.dataset.paymentid);
      if (this.dataset.amount != "undefined" && typeof(this.dataset.amount) != "undefined") {
          total += parseFloat(this.dataset.amount);

          var markup = "<tr><td><input type='hidden' name='payment_ids[]' value='" + this.dataset.paymentid + "'>" + this.dataset.invoice + "</td><td>" + this.dataset.checknum + "</td><td>$" + parseFloat(this.dataset.amount) + "</td></tr>";
          $("table#check-batch").append(markup);
      }
    });

    $('#batchtotal-text').text(Number((total).toFixed(2)));

    var createBatchClickSave = document.querySelector('.finance-batch-save');
    EMMY.entrants.activeFormAjaxCall = true;
    createBatchClickSave.addEventListener('click', function(e) {
      if(!EMMY.entrants.activeFormAjaxCall){
        return;
      }
      EMMY.entrants.activeFormAjaxCall = false;
      e.preventDefault();
      var data = $('form#finance-batch-form-data').serialize();
      EMMY.modals.createBatchClickSave(data);
    });
  },
  createBatchClickSave: function( data) {
    $.ajax({
      type: "POST",
      url: '/api/batch/create',
      data: data,
      dataType: 'json',
      success: function (data) {
        console.log('Batch has been sent');
        EMMY.entrants.ajaxBlurActiveSave = true;
        $('.modal-close').click();
          window.location.reload(false);
      },
      error: function (data) {
        console.log("error saving batch");
      }
    });
  },
  financeSubmitCheck: function (orderId, data) {
    data += "&orderid="+orderId;
    $.ajax({
      type: "POST",
      url: '/api/order/recordcheck',
      data: data,
      dataType: 'json',
      success: function (data) {
        console.log('Check has been sent');
        orders = orderId.split(';');
        jQuery.each(orders, function(i, orderId){
          $('.order-'+orderId).remove();
        });
        $('.modal-close').click();
        $('.close-button').click();
      },
      error: function (data) {
        console.log("error saving check");
      }
    });
  },
  musicReportsResendAjax: function(entryId, emailValue) {
    $.ajax({
      type: "POST",
      url: '/reports/proof/music/resend?entry_id='+ entryId +'&email='+emailValue,
      // data: data,
      dataType: 'json',
      success: function (data) {
        console.log('Email has been sent');
        $('.modal-close').click();
      },
      error: function (data) {
        console.log("error saving check");
      }
    });
  },
  hairReportsResendAjax: function(entryId, emailValue) {
    $.ajax({
      type: "POST",
      url: '/reports/proof/hair_makeup/resend?entry_id='+ entryId +'&email='+emailValue,
      // data: data,
      dataType: 'json',
      success: function (data) {
        console.log('Email has been sent');
        $('.modal-close').click();
      },
      error: function (data) {
        console.log("error saving check");
      }
    });
  },
  previewCategory: function(entryId) {
    var previewURL = window.location.origin + '/entry/preview/'+entryId;
    var modalElement = document.getElementById('preview-modal-content');
    $('#preview-modal-content').html('<iframe allowTransparency="true" frameborder="0" scrolling="yes" src="'+previewURL+'" type= "text/javascript"></iframe>');
    var originalHref = $('#DownloadButton').data('hreforg');
    var hrefVal = originalHref + entryId + '/' + 'file';
    $('#DownloadButton').attr('href', hrefVal);
  },
}
