EMMY.entryAdmin = {
  init: function () {

    EMMY.entryAdmin.entryAdminTabs();
    EMMY.entryAdmin.statusSelectUpdate();
    EMMY.entryAdmin.internalNotes();
    EMMY.entryAdmin.proofCreateForm();
    EMMY.entryAdmin.reassignVoucher();
    EMMY.entryAdmin.refund();
    EMMY.entryAdmin.changeLogs();
    EMMY.entryAdmin.submitterAutocomplete();
    EMMY.modal_izimodal.init();
    
    $(document).on('click', '.new-modal-trigger[data-target="#refund-modal"]', function(e){
      target = $(this).data('target');
      $(target+' input[name=amount]').val($(this).data('amount'));
    });

    $('.downloadAsset').on('click', function(e) {
      var id = $(this).data('cfvid');
      var self = this;
      $.ajax({
        url: '/api/upload/updateLastViewed/'+id,
        method: 'POST',
        type: 'post',
        success: function (data) {
          $(self).closest('.dropzone.updated').removeClass('updated');
        },
        error: function() {
        }
      });
    })

    if (location.hash) {
      setTimeout(function() {

        window.scrollTo(0, 0);
      }, 1);
    }
  },
  entryAdminTabs: function() {
    $('.accordion-tabs').each(function(index) {
      if (window.location.hash) {
        var hash = $.trim(window.location.hash);
        $(hash).addClass('is-active').next().addClass('is-open').show();

        if(hash == '#messages') {
          EMMY.messages.readMessages();
        }

      } else {
        $(this).children('li').first().children('a').addClass('is-active').next().addClass('is-open').show();
      }
      //$(this).children('li').first().children('a').addClass('is-active').next().addClass('is-open').show();
    });
    $('.accordion-tabs').on('click', 'li > a.tab-link', function(event) {
      if (!$(this).hasClass('is-active')) {
        event.preventDefault();

        var accordionTabs = $(this).closest('.accordion-tabs');
        accordionTabs.find('.is-open').removeClass('is-open').hide();

        $(this).next().toggleClass('is-open').toggle();
        accordionTabs.find('.is-active').removeClass('is-active');
        $(this).addClass('is-active');

        // Ensure the prev/next button has the proper hash url
        var idVal = $(this).attr('id');
        EMMY.entryAdmin.addAnchorToPrevNextLink(idVal);

        //if this is messages tab, set messages as read
        if($(this).attr('id') == 'messages') {
          EMMY.messages.readMessages();
        }
      } else {
        event.preventDefault();
      }
    });

    if($('.entry-admin-prev-next').val() != undefined) {
      var idVal = $('.tab-link.is-active').attr('id');
      EMMY.entryAdmin.addAnchorToPrevNextLink(idVal);
    }
  },
  addAnchorToPrevNextLink: function(idVal) {
    if(idVal != undefined) {
      $('.top-buttons .entry-admin-prev-next').each(function (i, v) {
        var hrefVal = $(this).data('hrefval') + '#' + idVal;
        $(this).attr('href', hrefVal);
      });
    } else {
      $('.top-buttons .entry-admin-prev-next').each(function (i, v) {
        var hrefVal = $(this).data('hrefval');
        $(this).attr('href', hrefVal);
      });
    }
  },
  statusSelectUpdate: function() {
    //add function to updates status and placement
    $('#entry-status-select').change(function() {
      var url = $(this).parents('div.select').data('url');
      var value = $(this).val();
      var text = $(this).children('option:selected').text();
      $('#entry-status').html(text);

      $.ajax({
        method: "POST",
        url: url,
        data: {field: "status", value: value},
        success: function (data) {
          //$('#entry-status').html(text);
          $('#timestamp').text(data.time);
        }
      });
    });

    $('#entry-placement-select').change(function() {
      var url = $(this).parents('div.select').data('url');
      var value = $(this).val();
      var text = $(this).children('option:selected').text();

      $.ajax({
        method: "POST",
        url: url,
        data: {field: "placement", value: value},
        success: function (data) {
          $('#entry-placement').html(text);
          $('#timestamp').text(data.time);
        }
      });
    });
  },
  internalNotes: function() {
    $('.add-internal-notes').click(function(e) {
      e.preventDefault();
      var text = $('#internal-notes-span').html();
      $('.internal-notes').removeClass('hidden').val(text);
      $(this).addClass('hidden');
      $('#internal-notes-span').addClass('hidden');
    });

    $('.internal-notes .cancel').click(function(e) {
      $('.internal-notes').addClass('hidden');
      $('.add-internal-notes').removeClass('hidden');
      $('#internal-notes-span').removeClass('hidden');
      var text = $('#internal-notes-span').html();
      $('.internal-notes div.wysiwyg').html(text);
    });

    $('.internal-notes .save').click(function(e) {
      e.preventDefault();
      var url = $(this).parent().data('url');
      var text = $('.internal-notes div.wysiwyg').html();

      $.ajax({
        method: "POST",
        url: url,
        data: {field: "internal_notes", value: text},
        success: function (data) {
          $('#internal-notes-span').html(text);
          $('#timestamp').text(data.time);
          if(text.length < 1) {
            $('.add-internal-notes').html('Click to Add');
          } else {
            $('.add-internal-notes').html('Edit');
          }
        }
      });

      $('.internal-notes').addClass('hidden');
      $('.add-internal-notes').removeClass('hidden');
      $('#internal-notes-span').removeClass('hidden');
    });
  },
  proofCreateForm: function() {
    $('.btn-note-send').click(function(e) {
      e.preventDefault();
      EMMY.entryAdmin.ajaxSubmitProofNote();
    });
    $('#noteCreate').on('keydown', function(e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        e.preventDefault();
        EMMY.entryAdmin.ajaxSubmitProofNote();
      }
    });

    EMMY.entryAdmin.proofNoteEdit();
    EMMY.entryAdmin.proofNoteDelete();

  },
  ajaxSubmitProofNote: function() {
    var form = $('#noteCreate');
    var text = form.find('input[name=note]');
    var entry_id = form.find('[name=entry_id]').val();
    var url = form.prop('action');
    $.ajax({
      type: "POST",
      url: url,
      data: form.serialize(),
      dataType: 'json',
      success: function (data) {
        $('textarea[name=note]').val('');
        $('ul.notes').prepend('<li style="display:none;">' + data.html + "</li>");
        $('ul.notes li:first').fadeIn();
        EMMY.entryAdmin.proofNoteEdit();
        EMMY.entryAdmin.proofNoteDelete();
      },
      error: function (data) {
        console.log("error submitting note");
      }
    });
  },
  ajaxSubmitProofNoteEdit: function(that) {
    var proofEditVal = that.closest('li').find('textarea').val();
    var proofData = {'note': proofEditVal};
    var noteId = that.closest('.note-wrapper').data('note-id');
    var url = '/api/proofnote/' + noteId;
    $.ajax({
      type: 'put',
      url: url,
      data: proofData,
      success: function (data) {
        that.closest('li').find('.proof-note-edit').addClass('hide-me');
        that.closest('li').find('.note-content').removeClass('hide-me');
        that.closest('li').find('.note-content').text(proofEditVal);
      },
      error: function (data) {
        console.log(data, "error updating note");
      }
    });
  },
  proofNoteEdit: function() {
    // Proof Note Edit
    $('.proofNote-edit').on('click', function(e) {
      e.preventDefault();
      var actualNoteEdit = $(this).closest('li');
      actualNoteEdit.find('.proof-note-edit').removeClass('hide-me');
      actualNoteEdit.find('.proof-note-edit').focus();
      actualNoteEdit.find('.note-content').addClass('hide-me');
    });

    $('.btn-note-send-edit').on('click', function(e) {
      e.preventDefault();
      var that = $(this);
      EMMY.entryAdmin.ajaxSubmitProofNoteEdit(that);
    });
    $('input.proof-edit').on('keydown', function(e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        e.preventDefault();
        var that = $(this);
        EMMY.entryAdmin.ajaxSubmitProofNoteEdit(that);
      }
    });
  },
  proofNoteDelete: function() {
    // Proof Note Delete
    $('.proofNote-delete').on('click', function(e) {
      e.preventDefault();
      var noteId = $(this).closest('.note-wrapper').data('note-id');
      var url = '/api/proofnote/' + noteId;
      var that = $(this);
      $.ajax({
        type: 'delete',
        url: url,
        data: '',
        success: function (data) {
          that.closest('li').remove();
        },
        error: function (data) {
          console.log(data,"error deleting note");
        }
      });
    });
  },
  reassignVoucher: function() {

    $('.btn-reassign-voucher').click(function(e) {
      var imis = $(this).data('imis');
      $('input[name=imis_id]').val(imis);
    });


  },
  reassignVoucherSubmit : function() {
    var form = $('#reassignVoucher');
    var url = form.prop('action');
    var entry_id = form.find('input[name=current_entry_id]').val();

    $.ajax({
      type: "POST",
      url: url,
      data: form.serialize(),
      success: function (data) {
        //window.location.hash = '#financials'
        //location.reload();
          $('.financials-content').html('<div class="alert alert-success">'+data.responseText+'</div>');

        $.get('/api/entry/' + entry_id + '/getFinancials', function(data) {
               $('.financials-content').append(data);
          });
      },
      error: function (data) {
          $('.financials-content').html('<div class="alert alert-success">'+data.responseText+'</div>');
          $.get('/api/entry/' + entry_id + '/getFinancials', function(data) {
              $('.financials-content').append(data);
          });
        console.log("error submitting reassignment");
      }
    });
  },

  refund: function() {

    $('.btn-refund').click(function(e) {
      var amount = $(this).data('amount');
      $('input[name=amount]').val(amount);
      $(document).one('click', '.refund-submit', function(e){
        e.preventDefault();
        EMMY.entryAdmin.refundSubmit();
        e.stopPropagation();
      });
    });


  },

  refundSubmit : function() {
    var form = $('#refund');
    var url = form.prop('action');
    var entry_id = form.find('input[name=current_entry_id]').val();

    $.ajax({
      type: "POST",
      url: url,
      data: form.serialize(),
      success: function (data) {
        //window.location.hash = '#financials'
        //location.reload();
        $('.financials-content').html('<div class="alert alert-success">'+data.responseText+'</div>');

        $.get('/api/entry/' + entry_id + '/getFinancials', function(data) {
          $('.financials-content').append(data);
        });

        $('#refund-modal').iziModal('close', {
          transition: 'fadeOutUp' // Here transitionOut is the same property.
        });
      },
      error: function (data) {
        $('.financials-content').html('<div class="alert alert-success">'+data.responseText+'</div>');
        $.get('/api/entry/' + entry_id + '/getFinancials', function(data) {
          $('.financials-content').append(data);
        });
        console.log("error submitting reassignment");
        $('#refund-modal').iziModal('close', {
          transition: 'fadeOutUp' // Here transitionOut is the same property.
        });
      }
    });
  },

  changeLogs: function() {
    $('.load-more-changelogs').click(function(e) {
      e.preventDefault();
      var offset = $('.changelog-table tr').length - 1;
      var url = $(this).prop('href') + '/' + offset;
      $.get(url, function(data) {
        $('.changelog-table').append(data);
        var count = $(data).filter('tr').length;
        if(count < 10) {
          $('.load-more-changelogs').hide();
        }
      })
    });

  },
  submitterAutocomplete: function() {

    var FORM = $('#entry-change-submitter');
    var VALUE = FORM.find('input[name=value]');
    $('#autocomplete').autocomplete({
      serviceUrl: '/api/entry/users/suggestions',
      minChars: 3,
      onSelect: function(suggestion) {

        VALUE.val(suggestion.data);
      }
    });

  },
  changeSubmitter: function(e) {
    var form = $('#entry-change-submitter');
    var url = form.prop('action');
    var newsub = form.find('input[name=autocomplete]').val()

    $.ajax({
      url: url,
      type: 'post',
      data: form.serialize(),
      success: function (data) {
        $('#timestamp').text(data.time);
        $('span.submitter-name').html(newsub);
      }
    });
  },
};
