//This is the functionality for the category
EMMY.categoryChanger = {
  init: function () {
    var categoryList = $('#categoryChanger');
    categoryList.select2({
      placeholder: 'Choose',
      dropdownPosition: 'below',
    });
    var initialCategory = categoryList.val();
    categoryList.on('change', function (e) {
      e.preventDefault();
      EMMY.modals.bodyClass.classList.toggle('modal-open-six');
      var catChanger = document.getElementById('category-changer');
      catChanger.setAttribute('data-newCat', this.value);
    });

    $('.modal-close-six').on('click', function() {
      categoryList.val(initialCategory).trigger('change');
      $('body').removeClass('modal-open-six');
    });
    $('.entries-changeCategory').on('click', function() {
      EMMY.categoryChanger.changeCategoryMidEntry();
    })
  },
  changeCategoryMidEntry: function () {
    var catChanger = document.getElementById('category-changer');
    catId = catChanger.getAttribute('data-newCat');
    entryId = $('input[name=entry_id]').val();
    $.ajax({
      url: "/api/entry/" + entryId + "/changeCategory/" + catId,
      method: 'POST',
      data: '',
      success: function (data) {
        console.log('great success');
        EMMY.modals.bodyClass.classList.toggle('modal-open-five');
        EMMY.modals.bodyClass.classList.toggle('modal-open-six');
        window.location.reload();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        alert('Error: could not change category');
        console.log('Error deleting entrant');
      }
    });

  }
};