EMMY.selectList = {
  init: function() {
    var categoryId = $('.category-auto-redirect');
    EMMY.selectList.init.createCategory = categoryId.select2({
      placeholder: 'Choose',
      dropdownPosition: 'below',
    });

    categoryId.on("change", function (e) {
      var clickedCategoryValue = this.value;
      if ($('.category-select.admin').val() != undefined) {
        window.location = this.value;
      } else {
        EMMY.category.changeCategory(clickedCategoryValue);
      }
    });
  },
}