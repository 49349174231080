//This is the functionality for the category
EMMY.categoryeditor = {
  init: function () {
    EMMY.categoryeditor.newEntryIndex = -1;
    var buttons = document.querySelectorAll('.category-form-wrapper .add-new-field');
    Array.from(buttons).forEach(function(button) {
        button.addEventListener('click', function (e) {
        e.preventDefault();
        var fieldType = this.getAttribute('data-fieldtype');
        var customFieldType = this.getAttribute('data-customfieldtype');
        EMMY.categoryeditor.addNewEntryField(fieldType, customFieldType );
      });
    });

    EMMY.categoryeditor.bindPreviewForm();
    EMMY.categoryeditor.bindSlideDown();
    EMMY.categoryeditor.bindMachineName();
    EMMY.categoryeditor.selectCreate();
    // EMMY.categoryeditor.sort();
    var addSelectButtons = document.querySelectorAll('.category-form-wrapper .add-new-select-item');
    EMMY.categoryeditor.bindNewSelectAddButtons(addSelectButtons);
    EMMY.categoryeditor.optionalSwitch();

  },
  bindNewSelectAddButtons: function (addSelectButtons) {
    Array.from(addSelectButtons).forEach(function(button) {
        button.addEventListener('click', function (e) {
        e.preventDefault();
        var fieldId = this.getAttribute('data-field-instance-id');
        var fieldType = this.getAttribute('data-field-type');
        EMMY.categoryeditor.addNewSelectItem(fieldId, fieldType);
      });
    });
    EMMY.categoryeditor.slideDown();
  },
  addNewEntryField: function (fieldType, customFieldType) {
    if (fieldType) {
      $.ajax({
        url: '/api/customfieldinstance/form/' +fieldType + '/' + customFieldType+ '/' + EMMY.categoryeditor.newEntryIndex,
        type: 'get',
        success: function (data) {

          var fields = $('#entryFields');
          fields.append(data);
          fieldsDOM = fields.get(0); //get js-style SOM reference
          var newButton = fieldsDOM.querySelectorAll('.add-new-select-item[data-field-instance-id="'+EMMY.categoryeditor.newEntryIndex+'"]');
          EMMY.categoryeditor.bindNewSelectAddButtons(newButton);
          EMMY.categoryeditor.bindSlideDown();
          EMMY.categoryeditor.bindPreviewForm();
          addedfield = document.querySelector('.field-wrapper[data-field-instance-id="'+EMMY.categoryeditor.newEntryIndex+'"]');
          // addedfield.classList.add("showme");
          EMMY.drag.init();
          EMMY.categoryeditor.newEntryIndex-=1;
          EMMY.categoryeditor.optionalSwitch();
          EMMY.common.wysiwyg();
        }
      });
    }
  },
  deleteEntryField: function (fieldId, cb) {
      var result = {success:true};
    if (fieldId && fieldId > 0) {
      $.ajax({
        url: '/api/category/customFieldInstance/' +fieldId ,
        type: 'delete',
        success: function (data) {
          return cb(result);
        },
          error: function (err) {
              result.success = false;
              result.error = err;
              return cb(result);
      }
      });
    }


  },
  addNewSelectItem: function (fieldId, fieldType) {
    if (fieldId) {
      $.ajax({
        url: '/api/category/newSelectItem/'+fieldType+'/' +fieldId ,
        type: 'get',
        success: function (data) {
          var fields = $('#select-items-'+fieldId);
          fields.append(data);
          EMMY.categoryeditor.bindPreviewForm();
          $('.fa-trash:not(.override-default)').on('click', function(e) {
            $(this).closest('div').remove();
          });
        }
      });
    }
  },
  bindSlideDown: function() {
    $('.field-wrapper').on('click', function(e) {
      e.stopPropagation();
      $('.field-wrapper').removeClass('showme');
      $(this).addClass('showme');
    });

    var editorClose = document.querySelectorAll('.close-icon');
    Array.from(editorClose).forEach(function(button) {
      button.addEventListener('click', function (e) {
        console.log('clicked');
        e.stopPropagation();
        $(this).closest('.field-wrapper').removeClass('showme');
      });
    });
    var editorTrash = document.querySelectorAll('.delete-field');
    Array.from(editorTrash).forEach(function(button) {
      button.addEventListener('click', function (e) {
        e.stopPropagation();
          var field = $(this).closest('.field-wrapper')
          var fieldId = field.attr('data-field-instance-id');
          if(confirm('Are you SURE you wish to delete this field? Doing so will delete it on any existing entries in the category.')) {
            if (fieldId > 0) { //fields that exist in db
              EMMY.categoryeditor.deleteEntryField(fieldId, function (result) {
                console.log(result);
                if (result.success) {
                  field.remove();
                } else {
                  alert("Error deleting field");
                }
              });
            } else {
              field.remove();
            }
          }
      });
    });

  },
  bindPreviewForm: function() {
    $('.form-group-beside:not(.optional_checkbox) input').each(function(i,v){
      $(this).on('keyup', function(e) {
        sectionToBind = $(this).closest('.form-group-beside').attr('class');
        EMMY.categoryeditor.inputBind(this, sectionToBind.split(' ')[1]);
      });
    });

    $('.selectItems input').on('change', function(e) {
      EMMY.categoryeditor.fixSelectListing(this);
    });

    $('.wysiwyg').on('tbwchange', function(e) {
      EMMY.categoryeditor.textAreaBind(this);
    });

    $('.checkbox-default-checker').on('change', function(e) {
      var previewGroup = $(this).closest('.field-wrapper');
      previewGroup.find('.binding-editor-area .checkboxDiv').toggleClass('checkedTrue');
    });
  },
  selectCreate: function() {
    $('.field-wrapper.select').each(function(i,v) {
        var select = $('<select name="options" id="options' + i + '"></select>');
        $(this).find('.form-group-beside.selectItems input').each(function (j, w) {
          var option = $('<option></option>');
          option.attr('value', $(this).val());
          option.text($(this).val());
          select.append(option);
        });
        console.log(select, 'this is the seclt val')
        $(this).find('.form-select').html(select);
    });
  },
  slideDown: function () {
    $('.collapse-toggle').on('click', function(e) {
        $(this).toggleClass('toggled');
        $(this).next().toggleClass('showme');
    });
  },
  textAreaBind: function(textareaVal) {
    var textareaTextVal = textareaVal.value;
    var previewGroup = $(textareaVal).closest('.field-wrapper');
    previewGroup.find('.preview-form-field').addClass('showme');
    previewGroup.find('.binding-editor-area .instructions-wrapper').html(textareaTextVal);
  },
  inputBind: function(inputVal, classToBind) {
    var inputTextVal = inputVal.value;
    var previewGroup = $(inputVal).closest('.field-wrapper');
    previewGroup.find('.preview-form-field').addClass('showme');
    switch (classToBind) {
      case 'display_name':
        previewGroup.find('.binding-editor-area .form-group-beside:not(.date-form):not(.logline-form) label').html(inputTextVal);
        previewGroup.find('.actualMachineName:not(.machineSet)').text(inputTextVal.replace(' ', '_').toLowerCase());
        if (previewGroup.hasClass('instructions_text')) {
          var inputHtmlVal = '<h1>' + inputTextVal + '</h1>';
          previewGroup.find('.binding-editor-area .instructions-wrapper-label').html(inputHtmlVal);
        }
        break;
      case 'hint_text':
        previewGroup.find('.binding-editor-area .form-group-beside:not(.date-form):not(.logline-form) .category_editor_preview_value').html(inputTextVal);
        break;
      case 'hover_text':
        previewGroup.find('.binding-editor-area .form-group-beside:not(.date-form):not(.logline-form) .category_editor_preview_value').attr('title', inputTextVal);
        break;
      case 'airdate_name':
        previewGroup.find('.binding-editor-area .form-group-beside.date-form label').html(inputTextVal);
        break;
      case 'logline_name':
        previewGroup.find('.binding-editor-area .form-group-beside.logline-form label').html(inputTextVal);
        break;
      case 'logline_description':
        previewGroup.find('.binding-editor-area .form-group-beside.logline-form .category_editor_preview_value').html(inputTextVal);
        break;
      case 'logline_hint_text':
        previewGroup.find('.binding-editor-area .form-group-beside.logline-form .category_editor_preview_value').attr('title', inputTextVal);
        break;
    }
  },
  bindMachineName: function() {
    $('.machinename').on('click', function(e) {
      $(this).closest('.machine-header').find('input').toggleClass('showme');
    });
    $('.machinenameinput').each(function(i,v) {
      $(this).on('keyup', function() {
        this.value = this.value.toLowerCase();
        var inputVal = $(this).val().replace(' ', '_').toLowerCase();
        $(this).closest('.machine-header').find('.actualMachineName').text(inputVal);
      });
    });
    $(document).on('click', function (e) {
      if ($(e.target).closest(".machinenameinput").length === 0) {
        $(".machinenameinput").removeClass('showme');
      }
    });
  },
  fixSelectListing: function(that) {
    var fieldWrapperInAction = $(that).closest('.field-wrapper');
    var selectOption = fieldWrapperInAction.find('.binding-editor-area select');
    fieldWrapperInAction.find('.preview-form-field').addClass('showme');
    selectOption.html('');
    fieldWrapperInAction.find('.selectItems input').each(function (i, v) {
      var option = $('<option></option>');
      option.attr('value', $(this).val());
      option.text($(this).val());
      selectOption.append(option);
    });
  },
  optionalSwitch: function() {
    $('.optional_checkbox input.form-control').on('change', function(e) {
      $(this).closest('.field-wrapper').find('.binding-editor-area label em').toggleClass('hide-me');
    });
  }
};