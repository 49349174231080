EMMY.instructions = {
  init: function () {
    $('.hide-instructions').on('click', function(e) {
      e.preventDefault();
      var closestInstructions = $(this).closest('.full-instructions-wrapper');
      closestInstructions.find('.instructions-wrapper').addClass('hide-me');
      closestInstructions.find('.show-instructions').removeClass('hide-me');
      $(this).addClass('hide-me');
      var instructionVar = closestInstructions.attr('data-instructions');
      if($('.admin-instructions').val() != undefined) {
        localStorage.removeItem('hide-instructions-'+ EMMY.entrants.entryId + '-'+instructionVar);
      } else {
        localStorage.setItem('hide-instructions-' + EMMY.entrants.entryId + '-' + instructionVar, '1');
      }
    });
    $('.show-instructions').on('click', function(e) {
      e.preventDefault();
      var closestInstructions = $(this).closest('.full-instructions-wrapper');
      closestInstructions.find('.instructions-wrapper').removeClass('hide-me');
      closestInstructions.find('.hide-instructions').removeClass('hide-me');
      $(this).addClass('hide-me')
      var instructionVar = closestInstructions.attr('data-instructions');
      if($('.admin-instructions').val() != undefined) {
        localStorage.setItem('hide-instructions-' + EMMY.entrants.entryId + '-' + instructionVar, '1');
      } else {
        localStorage.removeItem('hide-instructions-'+ EMMY.entrants.entryId + '-'+instructionVar);
      }
    });
    EMMY.instructions.localStorageInstructionsPull();
  },
  localStorageInstructionsPull: function() {
    $('.full-instructions-wrapper').each(function(i,v) {
      var dataIns = $(this).data('instructions');
      if($('.admin-instructions').val() != undefined) {
        if(localStorage.getItem('hide-instructions-'+ EMMY.entrants.entryId + '-'+dataIns)) {
          $(this).find('.instructions-wrapper').removeClass('hide-me');
          $(this).find('.instructions-wrapper-label .btn').toggleClass('hide-me');
        }
      } else {
        if(localStorage.getItem('hide-instructions-'+ EMMY.entrants.entryId + '-'+dataIns)) {
          $(this).find('.instructions-wrapper').addClass('hide-me');
          $(this).find('.instructions-wrapper-label .btn').toggleClass('hide-me');
        }
      }
    });
  }
};