UTIL = {

  fire : function(func,funcname, args){
    var namespace = EMMY;  // indicate your obj literal namespace here
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] == 'function'){
      namespace[func][funcname](args);
    }
  },

  loadEvents : function(){
    var bodyId = document.body.id;
    // hit up common first.
    if (bodyId != 'noCommon') {
      UTIL.fire('common');
    }


    // do all the classes too.
    if (document.body.className.length > 0) {
      $.each(document.body.className.split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        if (bodyId != '') {
          UTIL.fire(classnm, bodyId);
        }
      });
    }
  }
};

document.addEventListener("DOMContentLoaded", function() {
  UTIL.loadEvents();
})