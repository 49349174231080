var windowWidth;
var windowHeight;
var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
EMMY = {
  common: {
    init: function () {
      // This is the inital entrant country function to run on load
      var entrantCountry = $('body.signature .entrant_country');
      var entrantState = $('body.signature .entrant_state_province');

      if ((entrantCountry.val() == undefined) && ($('form#entry-fulldetails-form').val() != undefined)) {
        $('<input>').attr('type','hidden').addClass('entrant_country').appendTo('form#entry-fulldetails-form .entry-edit');
        var entrantCountry = $('.entrant_country');
        entrantCountry.val('United States').trigger('change');
      }

      var closestForm = entrantCountry.closest('form');
      if(entrantCountry.val() == '') {
        entrantCountry.val('United States').trigger('change');
        _stateProvinceAjax(closestForm, '');
      } else if ((entrantCountry.val() != 'United States') && (entrantCountry.val() != 'Canada')) {
        _stateProvinceAjax(closestForm, '');
      } else {
        var stateValFromDb = entrantState.attr('data-selval');
        _stateProvinceAjax(closestForm, stateValFromDb);
      }

      EMMY.common.questionMark();
      EMMY.common.alert();
      EMMY.common.scrollUp();
      EMMY.common.toggleYesNo();
      EMMY.common.selectList();
      EMMY.common.datePicker();
      EMMY.common.CSRFHeader();
      EMMY.common.wysiwyg();
      EMMY.common.adminURLUpdates();
      EMMY.common.submitOnce();
      EMMY.common.numEntriesRedirect();
      EMMY.common.selectAll();
      EMMY.common.skipTabItems();
      if($('.laa-site').length > 0) {
        EMMY.common.runTimeMask();
      }
      EMMY.common.entrantStateChanged = false;

      var w=window,
        d=document,
        e=d.documentElement,
        g=d.getElementsByTagName('body')[0];
        windowWidth=w.innerWidth||e.clientWidth||g.clientWidth,
        windowHeight=w.innerHeight||e.clientHeight||g.clientHeight;
    },
    questionMark: function() {
      $('#questionPop').on({
        mouseenter: function () {
          $('#questionPopContent').addClass('showme');
        },
        mouseleave: function () {
          $('#questionPopContent').removeClass('showme');
        }
      });

    },
    alert: function() {
      if (document.querySelector('.alert-header .close-icon')) {
        var closeIcon = document.querySelectorAll('.alert-header .close-icon');
        Array.from(closeIcon).forEach(function(link) {
          link.addEventListener('click', function () {
            this.parentElement.parentElement.className += ' close';
          });   
        });
      }
    },
    scrollUp: function() {
      $('.scroll-to-top').on('click', function(e) {
        e.preventDefault();
        EMMY.common.scrollToTop();
      });
      $('.scroll-to-bottom').on('click', function(e) {
        e.preventDefault();
        EMMY.common.scrollToBottom();
      });
      $(window).scroll(function(){
        $('.scroll-to-bottom').css("top", Math.max(10, 120 - $(this).scrollTop()));
      });
    },
    scrollToBottom: function(showError, IMISLastName) {
      var totalHeight = $(document).height();
      $('html, body').animate({ scrollTop: totalHeight }, 1000);
    },
    toggleYesNo: function() {
      // $('.toggle-group-beside .form-control').each(function(i,v) {
      //   if($(this).prop('checked')) {
      //     var yesNo = $(this).attr('class').split(' ');
      //     $(this).closest('.toggle-group-beside').find('.toggle-js').find('.' + yesNo[2]).addClass('active');
      //   }
      // });
      $(document).on('click', '.toggle-group-beside span', function(e) {
        var clickClass = $(this).attr('class');
        var yesNoClicked = false;
        var requiredYesNo = false;
        var groupOfToggle = $(this).closest('.toggle-group-beside');
        groupOfToggle.find('.form-control').each(function(i,v) {
          console.log($(this).attr('required') == 'required', 'required?')
          if($(this).is(":checked")) {
            yesNoClicked = true;
          }
          if($(this).attr('required') == 'required') {
            requiredYesNo = true;
          }
        });

        if(((yesNoClicked) && !$(this).hasClass('active')) || (requiredYesNo)) {
          // Toggle yes/no -- must be here if field is required
          $(this).parent().find('span').removeClass('active');
          groupOfToggle.find('.form-control').prop('checked', false);
          var yesNo = $(this).attr('class');
          groupOfToggle.find('.form-control.' + yesNo).prop('checked', true);
          $(this).addClass('active');
        } else {
          // Uncheck yes/no
          if ($(this).hasClass('active')) {
            $(this).toggleClass('active');
          }
          var clickClassYes = $(this).attr('class');
          if (groupOfToggle.find('.' + clickClassYes).is(":checked")) {
            groupOfToggle.find('.form-control.' + clickClassYes).val('');
          } else {
            $(this).addClass('active');
            groupOfToggle.find('.form-control.' + clickClassYes).prop('checked', true);
          }
        }
        groupOfToggle.find('.form-control').trigger('change');
      });

    },
    selectList: function() {
      if (document.querySelector('select')) {
        $('.main-wrapper select').each(function(i, v) {
          var that = this;
          if (($(this).hasClass('category')) || ($(this).hasClass('plain-select'))) {
            return;
          }
          var searchBox = 1;
          if ($(this).hasClass('no-click-text')) {
            searchBox = 'Infinity';
          }
          if ($(this).attr('multiple')) {
            EMMY.common.select2Multiple(this);
          } else if ($(this).hasClass('state')) {
            var actualStateDD = $(this).select2({dropdownPosition: 'below'});
            var closestForm = _getClosestForm($(this));

            if ($(this).val() == '') {
              _stateProvinceAjax(closestForm, '');
            }

          } else if ($(this).attr('required') == 'required') {
            EMMY.common.select2Required(this, searchBox);
          } else {
            EMMY.common.select2NotRequired(this, searchBox);
          }
        });
      }
    },
    select2Required: function(that, searchBox) {
      var chooseText = 'Choose';
      if($('#entry-judgement-form').val() != undefined) {
        var chooseText = 'Select Answer';
      }

      var requiredSelect = $(that).select2({
        placeholder: chooseText,
        dropdownPosition: 'below',
        minimumResultsForSearch: searchBox,
        matcher: function(params, data) {
          return matchStart(params, data);
        }
      });

      // // This is needed to open the tab on focus
      // $(that).closest('.form-group-beside').find('.select2-selection').on('focus', function() {
      //   requiredSelect.select2('open');
      // });

      $(that).on("change", function (e) {
        var closestForm = _getClosestForm(that);
        if($(that).hasClass('entrant_country')) {
          _stateProvinceAjax(closestForm, '');
        }
      });
      $(that).on('select2:close', function(e) {
        if($(this).hasClass('last-entrant-field-dontfocus')) {
          $(this).attr('tabindex', -1);
        } else if(!iOS || $(this).closest('.form-group-beside').next().find('.form-control').prop("tagName") != "SELECT") {
          if($(this).closest('.form-group-beside').next().find('.form-control').val() != undefined) {
            $(this).closest('.form-group-beside').next().find('.form-control').focus();
          } else if ($(this).closest('form').find('.episode_name_block').val() != undefined) {
            $(this).closest('form').find('.episode_name_block .form-group-beside').first().find('.form-control').focus();
          } else {
            $('#entrants-form .entrant-right .entrant-item').first().find('.first_name').focus();
          }
        }
      });
    },
    select2NotRequired: function(that, searchBox) {
      var chooseText = 'Choose';
      if($('#entry-judgement-form').val() != undefined) {
        var chooseText = 'Select Answer';
      }

      var notRequiredSelect = $(that).select2({
        placeholder: chooseText,
        dropdownPosition: 'below',
        minimumResultsForSearch: searchBox,
        matcher: function(params, data) {
          return matchStart(params, data);
        },
      });

      // // This is needed to open the tab on focus
      // if($('.entrants').val() != undefined){
      //   $(that).closest('.form-group-beside').find('.select2-selection').on('focus', function() {
      //     notRequiredSelect.select2('open');
      //   });
      // }

      $(that).on("select2:select", function (e) {
        var groupInFocus = $(this).closest('.form-group-beside');
        if(groupInFocus.hasClass('machine-header')) {
          var machineNameValue = $(this).val();
          groupInFocus.find('.actualMachineName').text(machineNameValue);
          groupInFocus.find('.machinenameinput').val(machineNameValue);
        }
        console.log(e, 'balll')
      });
      $(that).on('select2:close', function(e) {
        if(!$(this).closest('.form-group-beside').hasClass('machine-header')) {
          if($(this).closest('.form-group-beside').next().find('.form-control').val() != undefined) {
            $(this).closest('.form-group-beside').next().find('.form-control').focus();
          } else if ($(this).closest('form').find('.episode_name_block').val() != undefined) {
            $(this).closest('form').find('.episode_name_block .form-group-beside').first().find('.form-control').focus();
          } else {
            $('#entrants-form .entrant-right .entrant-item').first().find('.first_name').focus();
          }
        }
      });
      $('.machinenameinput').on('keyup', function(e) {
        $(this).closest('.form-group-beside').find('select').val('').trigger('change');
      });
    },
    // stateProvinceSelect: function(that, actualStateDropdown) {
    //   var countryDD = $(that);
    //   _stateProvinceAjax(countryDD, actualStateDropdown);
    // },
    select2Multiple: function(that) {
      var multiSelect = $(that).select2({
        closeOnSelect: false
      });
      if (!$(that).hasClass('entrant_gender') && !$(that).hasClass('entrant_ethnicity') && !$(that).hasClass('entrant_pronouns') && !$(that).hasClass('entrant_disability_type')) {
        EMMY.common.addSelectAll(that);
      }

      $(that).on("select2:unselect", function(e) {
        if (!e.params.originalEvent) {
          return
        }

        e.params.originalEvent.stopPropagation();
      })
      // $(that).on('select2:unselect', function(e) {
      //   console.log('here');
      //   multiSelect.select2('close');
      // });
    },
    datePicker: function() {

      if (document.querySelector('select.form-control.datePicker:not(.flatpickr-input)')) {
        $('select.form-control.date').pickadate({
          format: 'mm-dd-yyyy',
        });
      }
      if(document.querySelector('.date-secondformat')) {
        $('.date-secondformat').pickadate({
          format: 'yyyy-mm-dd',
        });
      }
      if(document.querySelector('.date-time')) {
        var timeInput = pickerTime = [];
        $('.date-time').each(function(i,v) {
          timeInput[i] = $(this).pickatime({
            format: 'h:i A'
          });
          pickerTime[i] = timeInput[i].pickatime('picker');
        });
        $('span.clock').on('click', function(e) {
          e.stopPropagation()
          var j = $('span.clock').index(this);
          pickerTime[j].open();
        });
      }

      var dateInput = pickerDate = [];
      $('.datepicker:not(.flatpickr-input)').each(function(i,el) {
        config = {
          format: "mm-dd-yyyy",
          onClose: function() {
            EMMY.common.toggleYesNo();
          }
        };
        if(($('.admin-view').val() == undefined) && ($('.entryAdmin').val() == undefined)) {
          if(el.getAttribute('data-mindate')) {
            config.min = el.getAttribute('data-mindate'); //minDate;
          }
          if(el.getAttribute('data-maxdate')) {
            config.max = el.getAttribute('data-maxdate'); //maxDate;
          }
        }
        dateInput[i] = $(this).pickadate(config);
        pickerDate[i] = dateInput[i].pickadate('picker');
      });
      $('span.calendar').on('click', function(e) {
        e.stopPropagation()
        var j = $('span.calendar').index(this);
        pickerDate[j].open();
      });
    },
    wysiwyg: function() {
      $.trumbowyg.svgPath = '/images/icons.svg';
      $('.wysiwyg').trumbowyg({
        removeformatPasted: true,
        autogrow: true,
        btns: [
          ['viewHTML'],
          ['formatting'],
          ["bold","italic","underline"],
          'btnGrp-design',
          ["strikethrough",'superscript', 'subscript'],
          ['link'],
          ['insertImage'],
          ['unorderedList', 'orderedList'],
          'btnGrp-justify',
          'btnGrp-lists',
          ['horizontalRule'],
          ['removeformat'],
          ['fullscreen']
        ]
      });
    },
    scrollToTop: function(showError, IMISLastName) {
      $('html, body').animate({ scrollTop: 0 }, 1000);
      if (showError) {
        $('.oneColumn .alert:not(.modal-alert) h2').text('Please complete all required fields underlined in red.');
        $('.oneColumn .alert:not(.modal-alert)').removeClass('close');
        $('.oneColumn .alert:not(.modal-alert)').removeClass('hide-me');
      }
    },
    selectAll: function() {
      $('.select-all').on('click', function(e) {
        var inputBox = $(this).closest('table').find('.table-checkbox:not(.header) input');
        if(inputBox.hasClass('checked')) {
          inputBox.removeClass('checked');
          inputBox.prop('checked', false);
        } else {
          inputBox.addClass('checked');
          inputBox.prop('checked', true);
        }
      });
    },
    adminURLUpdates: function() {
      $('.sidebar-update').each(function() {
        var storageVariable = $(this).data('localstorage-variable');
        if (localStorage.getItem(storageVariable)) {
          var paramsVals = localStorage.getItem(storageVariable)
        } else {
          var paramsVals = '';
        }
        var currentHref = $(this).attr('href');
        $(this).attr('href', currentHref + paramsVals);
      });
    },
    CSRFHeader: function() {
      $.ajaxSetup(
        {
          headers:
          {
            'X-CSRF-Token': $('input[name="_token"]').val()
          }
        });
    },
    submitOnce: function() {
      $('form.submit-once').submit(function(e){
        if( $(this).hasClass('form-submitted') ){
          e.preventDefault();
          return;
        }
        $(this).addClass('form-submitted');
      });
    },
    numEntriesRedirect: function() {
      $('.num-entries-select').on('change', function(e) {
        window.location = $(this).val();
      });
    },
    addSelectAll: function(that) {
      var $selectAll = $('<li id="selecting-all-options">Select All</li>');
      var $selectNone = $('<li id="selecting-all-options-none">Select None</li>');
      $(that).on('select2:open', function(e) {
        $('.select2-dropdown').prepend($selectAll);
        $('.select2-dropdown').prepend($selectNone);
      });
      $selectAll.on('click', function (e) {
        $(that).find("option").prop("selected","selected");
        $(that).trigger("change");
        $(that).select2("close");
        $selectAll.hide();
        $selectNone.show();
      });
      $selectNone.on('click', function (e) {
        $(that).find("option").prop("selected",false);
        $(that).trigger("change");
        $(that).select2("close");
        $selectAll.show();
        $selectNone.hide();
      });
    },
    skipTabItems: function() {
      $('.wysiwyg-content').find('button, a').attr('tabindex', -1);
      $('.episode_name_block a').attr('tabindex', -1);
      $('#createNewEntrant').attr('tabindex', -1);
      var lastSelectEntrantField = $('.entrant-right .entrant-item .form-group-beside:last-child select');
      if(lastSelectEntrantField.length > 0) {
        lastSelectEntrantField.addClass('last-entrant-field-dontfocus');
        lastSelectEntrantField.attr('tabindex', -1);
      }
    },
    runTimeMask: function() {
      if(($('.run_time').length > 0) && ($('.categoryeditor').length < 1)) {
        var TimeOpts = {
          placeholder: '__:__',
          onKeyPress: function (v, ev, curField, opts) {
            var mask = curField.data('mask').mask;
            if(curField.data('mask-keycode') == 38) {
              $curField.val(Number($curField.val())+1);
            }

            if (curField.data('mask-isZero') && curField.data('mask-keycode') == 8)
              $(curField).val('');
            else if (v) {
              if (!/^(([0-9]$)|([0-9]{2}$))|(([0-9]{2})((:$)|(:[0-5]$)|(:[0-5][0-9]$)))$/.test(v))
                v = v.substring(0, v.length - 1);
              $(curField).val(v).data('mask-isZero', (v == '00:00'));
            }
            restrictTimeMask(curField)
          }
        };

        var runTimeInput = $('.run_time').mask('00:00', TimeOpts).on('blur', function () {
          var $this = $(this),
            v = $this.val();
          v = v.length == 0 ? '00:00' :
            (v.length == 1 ? v + '0:00' :
              (v.length == 2 ? v + ':00' :
                (v.length == 3 ? v + '00' :
                  (v.length == 4 ? v + '0' : v))));
          $this.val(v);
          restrictTimeMask($this)
        });
      }

      $('.main-wrapper').on('keydown', '.run_time', function(e) {
        var runTime = $(this).val();
        var timeEnteredBroken = runTime.split(':');
        if(isNaN(timeEnteredBroken[0]) || isNaN(timeEnteredBroken[1])) {
          timeEnteredBroken = ['00','00'];
        }
        if(e.keyCode == 38) {
          _increaseTime(timeEnteredBroken, $(this));
        }
        if(e.keyCode == 40) {
          _decreaseTime(timeEnteredBroken, $(this));
        }
      })

      var temp = 0;
      var to = to2 = null;
      var int = int2 = null;
      $('.main-wrapper').on('mousedown', '.fa-arrow-group .fa', function(e) {
        temp++;
        runTimeInput = $(this).closest('.form-group-beside').find('.run_time');
        var runTime = $(this).closest('.form-group-beside').find('.run_time').val();
        var timeEnteredBroken = runTime.split(':');
        if(isNaN(timeEnteredBroken[0]) || isNaN(timeEnteredBroken[1])) {
          timeEnteredBroken = ['00','00'];
        }
        if($(this).hasClass('fa-arrow-up')) {
          _increaseTime(timeEnteredBroken, runTimeInput);
          to = setTimeout(function () {
            to2 = setTimeout(function() {
              int2 = setInterval(function () {
                _increaseTime(timeEnteredBroken, runTimeInput);
              }, 10);
            }, 2000),
            int = setInterval(function () {
              _increaseTime(timeEnteredBroken, runTimeInput);
            }, 90);
          },500);
        }
        if($(this).hasClass('fa-arrow-down')) {
          _decreaseTime(timeEnteredBroken, runTimeInput);
          to = setTimeout(function () {
            to2 = setTimeout(function() {
              int2 = setInterval(function () {
                _decreaseTime(timeEnteredBroken, runTimeInput);
              }, 10);
            }, 2000),
              int = setInterval(function () {
                _decreaseTime(timeEnteredBroken, runTimeInput);
              }, 90);
          },500);
        }
      }).on("mouseup", function () {
        clearTimeout(to);
        clearTimeout(to2);
        clearInterval(int);
        clearInterval(int2);
      });
    }
  },
}


function restrictTimeMask(curField) {
  timeEntered = curField.val();
  var timeEnteredBroken = timeEntered.split(':');
  if(timeEnteredBroken[0] > 75) {
    curField.val('75:00');
  } else if ((timeEnteredBroken[0] == 75) && (timeEnteredBroken[1] > 0)) {
    curField.val('75:00');
  }
}

function _getClosestForm(that) {
  if ($('.entrant-item').val() != undefined) {
    var closestForm = $(that).closest('.entrant-item');
  } else {
    var closestForm = $(that).closest('form');
  }
  return closestForm;
}

function _stateProvinceAjax(closestForm, actualStateDropdownVal) {
  var countryVal = closestForm.find('.entrant_country').val();
  var stateSelect = closestForm.find('.entrant_state_province');
  if (countryVal == 'United States') {
    stateSelect.closest('.customfields-field').find('label em').removeClass('hide-me');
    stateSelect.prop('disabled', false);
    stateSelect.attr('required',true);
    getStates('/api/country/US/states', stateSelect, actualStateDropdownVal)
  } else if (countryVal == 'Canada') {
    stateSelect.closest('.customfields-field').find('label em').removeClass('hide-me');
    stateSelect.prop('disabled', false);
    stateSelect.attr('required',true);
    getStates('/api/country/CA/states', stateSelect, actualStateDropdownVal);
  } else {
    stateSelect.val(null).trigger('change');
    stateSelect.closest('.customfields-field').find('label em').addClass('hide-me');
    stateSelect.val(' ').removeAttr('required').prop('disabled', true);
  }
}

function getStates(stateURL, stateSelector, initialSelectVal) {
  stateSelector.empty();
  $.ajax({
    url: stateURL,
    type: 'GET',
  }).then(function (response) {
    var jsonData = {};
    jsonData.results = [];
    jsonData.results.push({
      id: '',
      text: '',
    })
    $.map(response, function (value, index) {
      jsonData.results.push({
        id: index,
        text: response[index],
      });
    });
    var actualStateDropdown = stateSelector.select2({
      placeholder: "Choose",
      data: jsonData.results,
      dropdownPosition: 'below',
      matcher: function(params, data) {
        return matchStart(params, data);
      },
    });
    // This is needed to open the tab on focus
    $(stateSelector).closest('.form-group-beside').find('.select2-selection').on('focus', function() {
      actualStateDropdown.select2('open');
    });

    $(stateSelector).val(initialSelectVal).trigger('change')

    stateSelector.on('select2:close', function(e) {
      if(!iOS || $(this).closest('.form-group-beside').next().find('.form-control').prop("tagName") != "SELECT") {
        $(this).closest('.form-group-beside').next().find('.form-control').focus();
      }
    })
    if(EMMY.common.entrantStateChanged) {
      actualStateDropdown.val(EMMY.common.entrantStateChanged).trigger('change.select2');
    }
  });
}

function matchStart(params, data) {
  params.term = params.term || '';
  if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) == 0) {
    return data;
  }
  return false;
}

function _increaseTime(timeEnteredBroken, that) {
  if(timeEnteredBroken[0] > 75) {
    that.val('75:00');
  } else if ((timeEnteredBroken[0] == 75) && (timeEnteredBroken[1] > 0)) {
    that.val('75:00');
  } else {
    var newVal = Number(timeEnteredBroken[1]) + 1;
    if(newVal > 59) {
      timeEnteredBroken[1] = '00';
      timeEnteredBroken[0] = Number(timeEnteredBroken[0]) + 1;
    } else {
      timeEnteredBroken[1] = newVal;
    }
    timeEnteredBroken[0].toString();
    timeEnteredBroken[1].toString();
    if(timeEnteredBroken[0].toString().length < 2) {
      timeEnteredBroken[0] = 0 + timeEnteredBroken[0].toString();
    }
    if(timeEnteredBroken[1].toString().length < 2) {
      timeEnteredBroken[1] = 0 + timeEnteredBroken[1].toString();
    }
  }
  that.val(timeEnteredBroken.join(':'));
}

function _decreaseTime(timeEnteredBroken, that) {
  if (timeEnteredBroken[0] < 0) {
    that.val('00:00');
  } else if ((timeEnteredBroken[0] <= 0) && (timeEnteredBroken[1] < 0)) {
    that.val('00:00');
  } else {
    var newVal = Number(timeEnteredBroken[1]) - 1;
    if (newVal < 0) {
      timeEnteredBroken[1] = '59';
      timeEnteredBroken[0] = Number(timeEnteredBroken[0]) - 1;
    } else {
      timeEnteredBroken[1] = newVal;
    }
    timeEnteredBroken[0].toString();
    timeEnteredBroken[1].toString();
    if (timeEnteredBroken[0].toString().length < 2) {
      timeEnteredBroken[0] = 0 + timeEnteredBroken[0].toString();
    }
    if (timeEnteredBroken[1].toString().length < 2) {
      timeEnteredBroken[1] = 0 + timeEnteredBroken[1].toString();
    }
    if (timeEnteredBroken[0] < 0) {
      timeEnteredBroken[0] = '00';
      timeEnteredBroken[1] = '00';
    }
    that.val(timeEnteredBroken.join(':'));
  }
}