EMMY.fileUpload = {
  init: function() {
    console.log('file upload loaded');
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });

    $('.file-remove.btn-remove.custom-delete').on('click', function(){
      var field = $(this).attr('data-field');
      var appid = $(this).attr('data-appid');
      $('#fileDeleteModal').iziModal('open');
      $('#modal-fileYesDelete').on('click', function() {
        $.ajax('/api/uploadFile/'+appid+'/'+field, {
          method: "DELETE",
          success: function() {
            location.reload();
          },
          error: function() {
            alert('There was a problem with your request, please try again.');
            location.reload();
          }
        });
        
      });
      $('#modal-fileCancelDelete').on('click', function() {
        $('#fileDeleteModal').iziModal('close');
      });

    });
    $('.hide_result_file input').change(function(e){
      console.log('changed');
      var entry_id  = $(this).attr('data-entry');
      var field_id  = $(this).attr('data-field');
      var token     = $('input[name=upload_link_token]').val();
      var status    = this.checked?'hidden':'';

      $.ajax('/api/updatefileinfo', {
        method: "POST",
        data: { 'entry_id':entry_id, 'field_id':field_id, 'token':token, 'status':status},
        success: function (response) {
         //console.log(response);
        },
        error: function () {
          console.log('Update field error');
          alert('There was a problem updating the submission, please try again.');
          location.reload();
        }
      });
    });

    var allUploads = document.querySelectorAll('.btn-upload');
    Array.from(allUploads).forEach(function(uploadBox) {
      uploadBox.addEventListener('click', function (e) {
        console.log('e')
        $(this).prev().click();
        console.log('here/');
      });
    });
  }
}