EMMY.modal_izimodal = {
  init: function () {

    if($(".izimodal").length > 0) {
      $(".izimodal").iziModal({
        onClosing: function () {
          $('.modal_izimodal').removeClass('iziModal-opened-noscroll');
        }
      });
    }
    
    $(document).on('click', '.new-modal-trigger', function (event) {
      event.preventDefault();
      target = $(this).data('target');
      $(target).iziModal('open');
      $('.modal_izimodal').addClass('iziModal-opened-noscroll');

      if ($(this).hasClass('preview')) {
        $(target).iziModal('setWidth', '90%');
        $(document).on('closing', target, function (e) {
          // console.log('Modal is closing', $(this).html(), e);
          // var theProtocol = location.protocol,
          //     theURL = theProtocol + "//players.brightcove.net",
          //
          //     // Get a reference to the iframe element
          //     iframeTag = document.querySelector("iframe"),
          //
          //     // Retrieve window object needed for postMessage
          //     win = iframeTag.contentWindow;
          // console.log('theURL:', theURL);
          // console.log('win:', win);
          // win.postMessage("pauseVideo", theURL)
          // myPlayer.pause();
        })
      }
    });

  }
};
