EMMY.ballotFilter = {
  init: function () {
    if($('.search-table').val() != undefined) {
      var parameterVals = window.location.search;
      localStorage.setItem('searchURL', parameterVals);
      $('.clear-button').on('click', function () {
        localStorage.removeItem('searchURL');
      });
    }

    var inputWidth = $('.entries-table').width();

    $('#years').on('change', function(e) {
      var year = this.options[e.target.selectedIndex].text;
      window.location.href = window.location.pathname + "?years[]="+year;
    });


    $('select').on('select2:open', function(e) {
      $(this).closest('.choices-filter-group').find('.selection').addClass('rad-dropSelect');
      if($(this).attr('id') == 'categories') {
        $('.searchAdmin').addClass('fullWidthSearch');
        $('.fullWidthSearch .rad-dropSelect').css('width', inputWidth);
        $('.fullWidthSearch.reportProofHairMakeup .rad-dropSelect').css('width', 600);
        $('.fullWidthSearch > .select2-container .select2-dropdown').css('width', inputWidth);
      }
    });
    $('select').on('select2:close', function(e) {
      $(this).closest('.choices-filter-group').find('.selection').removeClass('rad-dropSelect');
      if($(this).attr('id') == 'categories') {
        $('.searchAdmin').removeClass('fullWidthSearch');
      }
    });

      $('.choices-filter-group select').each(function () {
        var selectLabel = $(this).closest('.choices-filter-group').find('h4').text();
        $(this).closest('.choices-filter-group').find('.dropdown-wrapper').text(selectLabel);

        var filterGrouping = 'filter-tags-' + $(this).attr('id');
        var that = $(this);
        _setOptionasButton(that, filterGrouping);
        $(this).on('change', function (e) {
          var that2 = $(this);
          _setOptionasButton(that, filterGrouping);
        });
      });
      $('body').on('click', '.name2', function(e) {
        var valueSelected = this.getAttribute('data-filter');
        var idVal = $(this).parent().attr('class').replace('filter-tags-','');
        _removeItem($('#' + idVal), valueSelected);
        $(this).remove();
      });

  },
};


function _setOptionasButton(that, filterGrouping) {
  var optionSelected = that.find("option:selected");
  $('.search-filters-added .' + filterGrouping).html('');
  for (var i = 0; i < optionSelected.length; i++) {
    var valueSelected = optionSelected[i].value;
    var textSelected = optionSelected[i].text;
    if (valueSelected != undefined) {
      var div = document.createElement('span');
      div.innerText = textSelected;
      div.className = 'name2';
      div.setAttribute('data-filter', valueSelected);
      var searchText = document.querySelector('.search-filters-added .'+filterGrouping);
      searchText.appendChild(div);
    }
  }
}


function _removeItem(selectList, idToRemove) {
  var values = selectList.val();
  if (values) {
    var i = values.indexOf(idToRemove);
    if (i >= 0) {
      values.splice(i, 1);
      if(values.length != 0) {
        selectList.val(values).change();
      } else {
        selectList.val('').change();
      }
    }
  }
}
