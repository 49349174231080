
EMMY.messages = {
  init: function () {
    EMMY.messages.sending = false;

    // on click send new message data to the server
    $('.btn-message-send').click(function(e){
      e.preventDefault();
      if (EMMY.messages.sending) {
        return;
      }
      $(this).text('Sending...');
      EMMY.messages.sending = true;
      ajaxSubmitMessage();
    });

    $('#messageCreate').on('keydown', function(e) {
      var keyCode = e.keyCode || e.which;
      if (EMMY.messages.sending) {
        return;
      }
      if (keyCode === 13) {
        EMMY.messages.sending = true;
        $('.btn-message-send').text('Sending...');
        e.preventDefault();
        ajaxSubmitMessage();
      }
    });

    $('#messageCreate').on('submit', function(e) {
      e.preventDefault();
      return;

    });

    //used for admin page to filter messages by user assignment
    $('select[name=user-assigned]').change(function(e) {
      var id = $(this).val();
      var url = window.location.origin + window.location.pathname + '?filter=' + id;
      window.location.href = url;
    });

    if($('div#messages').length > 0) {
      ajaxReadMessages();
    }

    if($('.message-input').val() != undefined) {
      var messageInput = $('.message-input');
      autoGrow(messageInput);
    }
    if($('.messsage-input-form').val() != undefined) {
      var messageInput = $('.messsage-input-form textarea');
      autoGrow(messageInput);
    }

    EMMY.messages.adminEditMessages();
  },
  readMessages: function(e) {
    ajaxReadMessages();
  },
  adminEditMessages: function(e) {
    $(document).on('click', '.edit-message', function(e) {
      e.preventDefault();
      var messageLi = $(this).closest('li');
      var messageDiv = $(this).closest('li').find('.message-content');
      var messageOrgDiv = messageDiv.find('.org-content');
      var messageContent = messageOrgDiv.text();

      _hideAnyOtherEditingMessages();

      var input = document.createElement("textarea");
      input.setAttribute('spellcheck', true);
      input.value = messageContent;
      messageDiv.append(input);

      messageLi.find('.save-cancel-buttons').removeClass('hide-me');
      $(this).addClass('hide-me');
      messageOrgDiv.addClass('hide-me');
    });

    $(document).on('click', '.save-edit-message', function(e) {
      e.preventDefault();
      var messageLi = $(this).closest('li');
      var messageDiv = $(this).closest('li').find('.message-content');
      var messageOrgDiv = messageDiv.find('.org-content');

      if($(this).hasClass('btn-cancel')) {
        messageDiv.find('textarea').remove();
      } else {
        var messageVal = {'message': messageDiv.find('textarea').val()};
        var messageId = $(this).data('message-id');
        var messageEditUrl = '/messages/'+ messageId +'/edit';
        $.ajax({
          url: messageEditUrl,
          type: 'put',
          data: messageVal,
          success: function (data) {
            messageOrgDiv.text(messageVal.message);
            messageDiv.find('textarea').remove();
          },
          error: function(jqXHR, textStatus, errorThrown) {
            console.log('error', jqXHR, textStatus, errorThrown);
          }
        });
      }

      messageLi.find('.edit-message').removeClass('hide-me');
      messageLi.find('.save-cancel-buttons').addClass('hide-me');
      messageOrgDiv.removeClass('hide-me');
    });



  }
};

function _hideAnyOtherEditingMessages() {
  $('.message-content textarea').remove();
  $('.org-content').removeClass('hide-me');
  $('.save-cancel-buttons').addClass('hide-me');
  $('.edit-message').removeClass('hide-me');
}

function ajaxSubmitMessage(actionItem) {
  var that = $('#messageCreate');
  if ($('#messageCreate textarea[name=message]').val() == '') {
    EMMY.messages.sending = false;
    $('.btn-message-send').text('SEND');
    return
  }
  var entry_id = that.find('[name=entry_id]').val();
  $.ajax({
    type: "POST",
    url: '/messages',
    data: that.serialize(),
    dataType: 'json',
    success: function (data) {

      console.log('Success message saved');
      $('textarea[name=message]').val('');
      if ($('.messages-main-page').val() != undefined) {
        window.location = '/messages/'+ entry_id;
      } else if ($('body').hasClass('entryAdmin')){
        $('ul.messages').prepend('<li style="display:none;">' + data.html + "</li>");
        $('ul.messages li:first').fadeIn();
      } else {
        ajaxMessageGrabber(entry_id, that);
      }
      EMMY.messages.sending = false;
      $('.btn-message-send').text('SEND');
    },
    error: function (data) {
      console.log("error submitting message");
      alert('There was a problem sending your message.');
      EMMY.messages.sending = false;
      $('.btn-message-send').text('SEND');
    }
  });
}

function ajaxMessageGrabber(entry_id, that) {
  $.ajax({
    type: "GET",
    url: '/api/messages/' + entry_id,
    success: function (data) {
      $('.messages-modal-content .actual-listing-messages').html(data);
      that.find('.message-input').val('');
      $('.messages-modal-content .alert').removeClass('hide-me');
    },
    error: function (data) {
      console.log("error getting message", data);
    }
  });
}

function ajaxReadMessages() {
  var url = $('#messages').data('url');
  $.ajax({
    method: "POST",
    url: url,
    success: function (data) {
      $('#messages').children('.messages-badge').remove();
      $('.left-sidebar .messages-badge:not(.stay)').html(data['count']);
    }
  });
}

function autoGrow(textareaInput) {
  textareaInput.keyup(function(e) {
    while($(this).outerHeight() < this.scrollHeight + parseFloat($(this).css("borderTopWidth")) + parseFloat($(this).css("borderBottomWidth"))) {
      $(this).height($(this).height()+1);
    };
  });
}